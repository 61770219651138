import {
    useAppInsightsContext,
    useTrackEvent,
} from '@microsoft/applicationinsights-react-js';
import { PasswordInput } from '@phx/design-system';
import { useForm } from '@phx/design-system/hooks';
import { useTranslation } from 'react-i18next';

export type FormWithCurrentPassword = { currentPassword: string };
export type CurrentPasswordFieldProps<T extends FormWithCurrentPassword> = {
    form: ReturnType<typeof useForm<T>>;
    label?: string;
    page: string;
};

export const CurrentPasswordField = <
    T extends FormWithCurrentPassword = FormWithCurrentPassword,
>({
    form,
    label,
    page,
}: CurrentPasswordFieldProps<T>) => {
    const { t } = useTranslation();
    const appInsights = useAppInsightsContext();

    const track = useTrackEvent(
        appInsights,
        `${page}_CURRENT_PASSWORD_CLICK`,
        {}
    );

    const currentPasswordProps = form.getInputProps('currentPassword');
    currentPasswordProps.onFocus = () => {
        track({ message: 'Current password is clicked' });
        form.getInputProps('currentPassword').onFocus();
    };

    return (
        <PasswordInput
            key={form.key('currentPassword')}
            name="currentPassword"
            label={label ? label : t('currentPasswordForm.currentPassword')}
            data-testid="currentPassword"
            {...currentPasswordProps}
        />
    );
};
