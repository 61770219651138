import { Alert } from '@phx/design-system';
import { useTranslation } from 'react-i18next';

export const GenericMessageError = () => {
    const { t } = useTranslation();
    return (
        <Alert data-testid="generic-message-error" severity="error">
            {t('formErrors.genericErrorMessage.errorOccured')}
        </Alert>
    );
};
