import { Alert } from '@phx/design-system';
import { useTranslation } from 'react-i18next';

export const InvalidPasswordError = () => {
    const { t } = useTranslation();
    return (
        <Alert data-testid="invalid-password-error" severity="error">
            {t('formErrors.invalidPasswordError.tryAgain')}
        </Alert>
    );
};
