import { ApolloError } from '@apollo/client';

import type { ErrorMessageTypes } from '../components/alerts/errors/error-map';
import { CONSTANTS } from '../constants';
import type { GenericApiResponse } from '../models/generic.api.response';
import { SendVerificationCodeMutation } from '../mutation/send-verification-code.mutation';

import { getStatusCodeFromApolloError } from './get-status-code-from-apollo-error';
import { SsoApiClient } from './sso-api-client';

export const sendVerificationCodeApi = async (
    phoneNumber: string
): Promise<GenericApiResponse> => {
    let error: ErrorMessageTypes = '500_GenericError';
    const sendVerificationCodeRequest = {
        contactType: CONSTANTS.otpContactType,
        contact: phoneNumber,
    };
    try {
        const { data } = await SsoApiClient.mutate({
            mutation: SendVerificationCodeMutation,
            errorPolicy: 'all',
            variables: {
                requestBody: sendVerificationCodeRequest,
            },
        });
        if (data && data.success) {
            return { success: true, error: undefined };
        }
    } catch (err) {
        const sendVerificationCodeError = err as ApolloError;
        error = getErrorMessageTypeForSendVerificationCodeApi(
            sendVerificationCodeError
        );
    }
    return { success: false, error };
};

const getErrorMessageTypeForSendVerificationCodeApi = (
    error?: ApolloError
): ErrorMessageTypes => {
    if (error) {
        const statusCode = getStatusCodeFromApolloError(error);
        switch (statusCode) {
            case 400:
                return '400_BadPhoneNumberError';
            case 429:
                return '429_MaxAttemptReached';
        }
    }
    return '500_GenericError';
};
