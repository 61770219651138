import { Alert } from '@phx/design-system';
import { useTranslation } from 'react-i18next';

export const CodeResentInfo = () => {
    const { t } = useTranslation();

    return (
        <Alert data-testid="code-resent-info" severity="info">
            {t('codeResentInfo.sentNewText')}
        </Alert>
    );
};
