import {
    useAppInsightsContext,
    useTrackEvent,
} from '@microsoft/applicationinsights-react-js';
import { Body1, Heading1, Stack, useNavBar } from '@phx/design-system';
import { useForm, useHotkeys } from '@phx/design-system/hooks';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { z } from 'zod';

import { clearCookie } from '../../api/clear-cookie.api';
import { sendRecoveryCodeApi } from '../../api/send-recovery-code.api';
import { PageLayout } from '../../components';
import {
    ErrorMap,
    type ErrorMessageTypes,
} from '../../components/alerts/errors/error-map';
import { EmailField } from '../../components/form-fields/EmailField';
import { SubmitButton } from '../../components/form-fields/SubmitButton';
import type { SendRecoveryFormModel } from '../../models/form.models';
import { routes } from '../../routes';

import type { RecoverPasswordVerifyCodePageState } from './RecoverPasswordVerifyCodePage';

const RecoverPasswordSendCode = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const appInsights = useAppInsightsContext();
    const { setBackOverrideState } = useNavBar();

    const form = useForm({
        validateInputOnBlur: true,
        initialValues: {
            email: '',
        },
        schema: z.object({
            email: z
                .string()
                .email(t('formErrors.formErrorMessage.invalidEmail')),
        }),
    });

    const trackSubmit = useTrackEvent(
        appInsights,
        'RECOVER_PASSWORD_SEND_CODE_SUBMIT_CLICK',
        {}
    );
    const trackBackButton = useTrackEvent(
        appInsights,
        'RECOVER_PASSWORD_SEND_CODE_BACK_BUTTON_CLICK',
        {}
    );

    const [searchParams] = useSearchParams();

    const [loading, setLoading] = useState<boolean>(false);
    const [errorMessageType, setErrorMessageType] = useState<
        ErrorMessageTypes | undefined
    >();

    const callBackUrl = searchParams.get('callback');

    const ErrorMessage = errorMessageType ? ErrorMap[errorMessageType] : null;

    const onNavigateBack = useCallback(() => {
        trackBackButton({
            message: 'Back button in forgot password is clicked',
        });
        navigate(-1);
    }, []);

    useEffect(() => {
        setBackOverrideState({ overrideFn: onNavigateBack });
        return () => setBackOverrideState({ overrideFn: null });
    }, [onNavigateBack]);

    const onRestartRecoverFlow = () => {
        navigate({
            pathname: routes.recoverSendCode,
            search: window.location.search,
        });
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onSendRecoveryCode = async (data: any) => {
        trackSubmit({ message: 'Submit button in forgot password is clicked' });

        setErrorMessageType(undefined);
        setLoading(true);
        // Attempt to clear cookies so it does not get any issues from previous attempts
        await clearCookie(true, true);
        const formData = data as SendRecoveryFormModel;
        try {
            const {
                success: sendOtpSuccess,
                flowId,
                csrfToken,
                error: sendOtpError,
            } = await sendRecoveryCodeApi(formData.email, callBackUrl ?? '');

            if (sendOtpSuccess) {
                const verifyCodePageState: RecoverPasswordVerifyCodePageState =
                    {
                        email: data.email,
                        token: csrfToken ?? '',
                        flowId: flowId ?? '',
                    };
                navigate(
                    {
                        pathname: routes.recoverVerifyCode,
                        search: window.location.search,
                    },
                    { state: verifyCodePageState }
                );
                return;
            }
            setErrorMessageType(sendOtpError);
        } catch {
            setErrorMessageType('500_GenericError'); // TODO:
        }
        setLoading(false);
    };

    useHotkeys([['Enter', () => form.onSubmit(onSendRecoveryCode)]]);

    return (
        <PageLayout
            loadingOverlay={loading}
            header={
                errorMessageType &&
                ErrorMessage && (
                    <ErrorMessage onLinkClick={onRestartRecoverFlow} />
                )
            }
            heading={
                <Stack gap="sm">
                    <Heading1>
                        {t('recoverPasswordSendCode.recoverYourAccount')}
                    </Heading1>
                    <Body1>
                        {t('recoverPasswordSendCode.registeredEmail')}
                    </Body1>
                </Stack>
            }
            footer={
                <SubmitButton form="recover-password-send-code-form">
                    {t('common.continue')}
                </SubmitButton>
            }
        >
            <Stack gap="lg">
                <form
                    id="recover-password-send-code-form"
                    onSubmit={form.onSubmit(onSendRecoveryCode)}
                >
                    {/* Do not remove this dummy text. Enter key press does not work as expected when there is only one input in form */}
                    <input type="text" hidden={true} name={'dummy'} />
                    <EmailField
                        form={form}
                        page="RECOVER_PASSWORD_SEND_CODE"
                        label={t('recoverPasswordSendCode.emailAddress')}
                    />
                </form>
            </Stack>
        </PageLayout>
    );
};
export default RecoverPasswordSendCode;
