import { GraphQLError, GraphQLScalarType, Kind } from 'graphql';
const NCPDP_ID_REGEX = /^\d{7}$/;
export const ncpdpIdScalar = new GraphQLScalarType({
    name: 'NCPDPID',
    description: 'NCPDPID custom scalar type',
    serialize: (value) => {
        if (!(typeof value === 'string')) {
            throw new GraphQLError(`GraphQL serializer expected a 'string' but got ${typeof value}.`);
        }
        return validateNcpdpId(value);
    },
    parseValue: (value) => {
        if (!(typeof value === 'string')) {
            throw new GraphQLError(`GraphQL parser expected a 'string' but got ${typeof value}.`);
        }
        return validateNcpdpId(value);
    },
    parseLiteral(ast) {
        if (ast.kind === Kind.STRING) {
            return ast.value.toString();
        }
        throw new GraphQLError(`GraphQL parser expected a 'string'.`);
    },
});
function validateNcpdpId(value) {
    if (!isValidNcpdpId(value)) {
        throw new GraphQLError(`Provided value: ${value}, is not a valid ncpdp id.`, {
            extensions: { code: 'BAD_USER_INPUT' },
        });
    }
    return value;
}
export const isValidNcpdpId = (value) => NCPDP_ID_REGEX.test(value);
