export const CONSTANTS = {
    defaultAuth: 'OAUTH',
    privacyPolicyLink: 'https://prescryptive.com/privacy-policy/',
    termsConditionsLink: 'https://prescryptive.com/terms-of-use/',
    waPrivacyNoticeLink:
        'https://prescryptive.com/wa-consumer-health-data-privacy-notice/',
    minimumAccountCreationAge: 13,
    minimumPasswordLength: 6,
    statePrefixLocalStorage: 'STATE_',
    stateExpiryTime: 3600000,
    sessionKey: 'session',
    tokenKey: 'device-verification-token',
    otpVerifySource: 'sso',
    otpContactType: 'phone',
    dobMask: '00/00/0000',
    phonePlaceHolder: '(000) 000-0000',
    codeRegex: /^[0-9]{6}$/,
    emailRegex:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    nameRegex: /^[A-Za-z0-9]+(?:[-\s][A-Za-z0-9]+)*$/,
};
