import { GraphQLError, GraphQLScalarType, Kind } from 'graphql';
const NDC_REGEX = /^\d{10,11}$/;
export const ndcScalar = new GraphQLScalarType({
    name: 'NDC',
    description: 'NDC custom scalar type',
    serialize: (value) => {
        if (!(typeof value === 'string')) {
            throw new GraphQLError(`GraphQL serializer expected a 'string' but got ${typeof value}.`);
        }
        return isNdc(value);
    },
    parseValue: (value) => {
        if (!(typeof value === 'string')) {
            throw new GraphQLError(`GraphQL parser expected a 'string' but got ${typeof value}.`);
        }
        return isNdc(value);
    },
    parseLiteral: (ast) => {
        if (ast.kind === Kind.STRING) {
            return ast.value.toString();
        }
        throw new GraphQLError(`GraphQL parser expected a 'string'.`);
    },
});
function isNdc(value) {
    if (!NDC_REGEX.test(value)) {
        throw new GraphQLError(`Provided value: ${value}, is not a valid ndc.`, {
            extensions: { code: 'BAD_USER_INPUT' },
        });
    }
    return value;
}
