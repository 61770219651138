import { Alert, Anchor } from '@phx/design-system';
import { Trans } from 'react-i18next';

export interface AccountExistsErrorProps {
    onLinkClick: () => void;
}

export const AccountExistsError = (props: AccountExistsErrorProps) => {
    const { onLinkClick } = props;

    return (
        <Alert data-testid="account-exists-error" severity="error">
            <Trans i18nKey="formErrors.accountExistsError">
                An account exists with the entered details. Please review your
                information or <Anchor onClick={onLinkClick}>sign in</Anchor> if
                you recognize this account.
            </Trans>
        </Alert>
    );
};
