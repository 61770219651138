import { Alert, Anchor } from '@phx/design-system';
import { openTalkativeUI } from '@phx/talkative';
import { Trans } from 'react-i18next';

export const InvalidCredentialsError = () => (
    <Alert data-testid="invalid-credentials-error" severity="error">
        <Trans i18nKey="formErrors.invalidCredentialsError.message">
            {'Incorrect phone number or password. Please try again. '}
            {'Need help? '}
            <Anchor onClick={openTalkativeUI}>{'Contact support'}</Anchor>
            {'.'}
        </Trans>
    </Alert>
);
