import type { ApolloError, ServerError } from '@apollo/client';

import type { ErrorMessageTypes } from '../components/alerts/errors/error-map';
import {
    type AccountErrorResponse,
    isGeneralAccountErrorResponse,
} from '../mutation/verify-account.mutation';

export const getAccountErrorFromApolloError = (
    error: ApolloError
): ErrorMessageTypes => {
    if (error.networkError) {
        const serverError = error.networkError as ServerError;
        if (!serverError.statusCode) {
            return '500_GenericError';
        }
        switch (serverError.statusCode) {
            case 400: {
                const accountError = serverError.result as AccountErrorResponse;
                if (isGeneralAccountErrorResponse(accountError)) {
                    if (
                        accountError.error ===
                        'Multiple patients found for given demographics'
                    ) {
                        return '409_MultiplePatientsFoundForDemographic';
                    }
                    return '500_GenericError';
                }

                if (
                    accountError.IdentityMismatch &&
                    accountError.IdentityMismatch.length > 0
                ) {
                    return '400_MismatchedInformationError';
                } else if (
                    accountError.CredentialExists &&
                    accountError.CredentialExists.length > 0
                ) {
                    return '400_AccountExistsError';
                } else if (
                    accountError.EmailExists &&
                    accountError.EmailExists.length > 0 &&
                    accountError.PhoneExists &&
                    accountError.PhoneExists.length > 0
                ) {
                    return '400_PhoneEmailExistsError';
                } else if (
                    accountError.PhoneExists &&
                    accountError.PhoneExists.length > 0
                ) {
                    return '400_PhoneExistsError';
                } else if (
                    accountError.EmailExists &&
                    accountError.EmailExists.length > 0
                ) {
                    return '400_EmailExistsError';
                } else if (
                    accountError.KratosError &&
                    accountError.KratosError.length > 0
                ) {
                    return '400_PasswordSimilarAsIdError';
                }
                break;
            }
            case 500: {
                if (
                    serverError.message?.includes(
                        'Multiple patients found for given demographics'
                    )
                ) {
                    return '500_MultiplePatientsFoundError';
                }
                break;
            }
        }
    }
    return '500_GenericError';
};
