import { ApolloProvider } from '@apollo/client';
import {
    AppInsightsContext,
    AppInsightsErrorBoundary,
} from '@microsoft/applicationinsights-react-js';
import { ThemeProvider } from '@phx/design-system/framework';
import theme from '@phx/phx-theme';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import React from 'react';
import { createRoot } from 'react-dom/client';
import {
    type RouteObject,
    RouterProvider,
    createBrowserRouter,
} from 'react-router-dom';

import { SsoApiClient } from './api/sso-api-client';
import { Layout } from './components/layout/Layout';
import { createApplicationInsights } from './instrumentation';
import AccountCreationError from './pages/account-creation-error/AccountCreationErrorPage';
import ChangePassword from './pages/change-password/ChangePasswordPage';
import InitiateProfileUpdate from './pages/initiate-profile-update/InitiateProfileUpdatePage';
import InitiateTokenCallback from './pages/initiate-token-callback/InitiateTokenCallbackPage';
import NotFoundPage from './pages/not-found/NotFoundPage';
import RecoverPassword from './pages/recover-password/RecoverPasswordPage';
import RecoverPasswordSendCode from './pages/recover-password/RecoverPasswordSendCodePage';
import RecoverPasswordVerifyCode from './pages/recover-password/RecoverPasswordVerifyCodePage';
import RecoverPasswordLink from './pages/recover-password-link/RecoverPasswordLinkPage';
import Signin from './pages/sign-in/SignInPage';
import Signup from './pages/sign-up/SignUpPage';
import SignupChallengeQuestions from './pages/sign-up-challenge-questions/SignUpChallengeQuestionsPage';
import SsoRedirect from './pages/sso-redirect/SsoRedirectPage';
import VerifyAccount from './pages/verify-account/VerifyAccountPage';
import { routes } from './routes';

import '@phx/phx-theme/theme.css';
import './i18n';
import './index.css';

const allowedRoutes: RouteObject[] = [
    {
        element: <Layout />,
        children: [
            {
                path: routes.signIn,
                element: <Signin />,
            },
            {
                path: routes.signUp,
                element: <Signup />,
            },
            {
                path: routes.signUpError,
                element: <AccountCreationError />,
            },
            {
                path: routes.signUpChallengeQuestions,
                element: <SignupChallengeQuestions />,
            },
            {
                path: routes.verify,
                element: <VerifyAccount />,
            },
            {
                path: routes.sso,
                element: <SsoRedirect />,
            },
            {
                path: routes.recoverSendCode,
                element: <RecoverPasswordSendCode />,
            },
            {
                path: routes.recoverVerifyCode,
                element: <RecoverPasswordVerifyCode />,
            },
            {
                path: routes.recover,
                element: <RecoverPassword />,
            },
            {
                path: routes.recoverLink,
                element: <RecoverPasswordLink />,
            },

            {
                path: routes.profile,
                element: <ChangePassword />,
            },
            {
                path: routes.update,
                element: <InitiateProfileUpdate />,
            },
            {
                path: routes.tokenCallback,
                element: <InitiateTokenCallback />,
            },
            {
                path: routes.root,
                element: <NotFoundPage />,
            },
            {
                path: routes.wildcard,
                element: <NotFoundPage />,
            },
        ],
    },
];

const router = createBrowserRouter(allowedRoutes, {
    basename: '/',
});
const ldClientSideID = import.meta.env.VITE_LAUNCH_DARKLY_CLIENT_ID;

const { reactPlugin } = createApplicationInsights();

asyncWithLDProvider({
    user: {
        anonymous: true,
    },
    clientSideID: ldClientSideID,
}).then((LDProvider) => {
    createRoot(document.getElementById('root') as HTMLElement).render(
        <React.StrictMode>
            <AppInsightsContext.Provider value={reactPlugin}>
                <LDProvider>
                    <ThemeProvider theme={theme}>
                        <AppInsightsErrorBoundary
                            onError={() => (
                                <h3>
                                    An unexpected error occurred during sign in.
                                    Please try again.
                                </h3>
                            )}
                            appInsights={reactPlugin}
                        >
                            <ApolloProvider client={SsoApiClient}>
                                <RouterProvider router={router} />
                            </ApolloProvider>
                        </AppInsightsErrorBoundary>
                    </ThemeProvider>
                </LDProvider>
            </AppInsightsContext.Provider>
        </React.StrictMode>
    );
});
