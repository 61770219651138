import {
    useAppInsightsContext,
    useTrackEvent,
} from '@microsoft/applicationinsights-react-js';
import { PasswordInput } from '@phx/design-system';
import { useForm } from '@phx/design-system/hooks';
import { useTranslation } from 'react-i18next';

import {
    type FormWithPassword,
    usePasswordValidation,
} from '../../hooks/use-password-validation';

export type PasswordFieldProps<T extends FormWithPassword> = {
    form: ReturnType<typeof useForm<T>>;
    label?: string;
    page: string;
    showHelpText?: boolean;
};

export const PasswordField = <T extends FormWithPassword = FormWithPassword>({
    form,
    label,
    page,
    showHelpText = true,
}: PasswordFieldProps<T>) => {
    const { t } = useTranslation();
    const appInsights = useAppInsightsContext();

    const track = useTrackEvent(appInsights, `${page}_PASSWORD_CLICK`, {});

    const { checkPassword } = usePasswordValidation(form);

    const inputProps = form.getInputProps('password');
    inputProps.onFocus = () => {
        track({ message: 'Password field is clicked' });
        form.getInputProps('password').onFocus();
    };
    delete inputProps.onBlur;

    const handleBlur = () => {
        if (form.values.password) {
            checkPassword(
                form.values.password,
                form.values.email,
                form.values.phoneNumber
            );
        } else {
            form.getInputProps('password').onBlur();
        }
    };

    return (
        <PasswordInput
            key={form.key('password')}
            name="password"
            label={label ? label : t('confirmPasswordForm.password')}
            data-testid="password"
            description={
                showHelpText
                    ? t('passwordHelpText.usePasswordSixChar')
                    : undefined
            }
            onBlur={handleBlur}
            {...inputProps}
        />
    );
};
