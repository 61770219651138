import { gql } from '@apollo/client';

export const VerifyAccountMutation = gql`
    mutation VerifyAccountMutation {
        verifyAccountResponse(input: $requestBody)
            @rest(
                type: verifyAccount
                method: POST
                path: "/credentials/verify"
            ) {
            success
            masterAccountId
            tryAdditionalInfo
        }
    }
`;

export const isGeneralAccountErrorResponse = (
    err?: AccountErrorResponse | null
): err is GeneralAccountErrorResponse => {
    /* @ts-expect-error -- the error property is on one version of the error response, but not the other */
    return typeof err?.error === 'string';
};

export type VerifyAccountModel = {
    firstName: string;
    lastName: string;
    dateOfBirth: string;
    phoneNumber: string;
    email: string;
    address?: {
        line1: string;
        line2?: string;
        city: string;
        state: string;
        country: string;
        postalCode: string;
    };
};

export type VerifyAccountRequest = {
    patient: VerifyAccountModel;
    patientProfile?: string;
    memberIdentifier?: string;
};

export type GeneralAccountErrorResponse = { error: string };

export type AccountErrorResponse =
    | {
          IdentityMismatch?: string[];
          CredentialExists?: string[];
          EmailExists?: string[];
          PhoneExists?: string[];
          KratosError?: string[];
          TryAdditionalInfo?: string[];
      }
    | GeneralAccountErrorResponse;

export type VerifyAccountSuccessResponse = {
    masterAccountId?: string;
    success: boolean;
};
