import {
    Body1,
    Heading1,
    Notification,
    Stack,
    useNavBar,
} from '@phx/design-system';
import { useForm, useHotkeys } from '@phx/design-system/hooks';
import { TimeExpiredIllustration } from '@phx/myphx-lib';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { z } from 'zod';

import { recoverPasswordApi } from '../../api/recover-password.api';
import { verifyRecoveryCodeLinkApi } from '../../api/verify-recovery-code-link.api';
import { GenericError, PageLayout } from '../../components';
import {
    ErrorMap,
    type ErrorMessageTypes,
} from '../../components/alerts/errors/error-map';
import { ConfirmPasswordField } from '../../components/form-fields/ConfirmPasswordField';
import { PasswordField } from '../../components/form-fields/PasswordField';
import { SubmitButton } from '../../components/form-fields/SubmitButton';
import { CONSTANTS } from '../../constants';
import { useConfirmPasswordValidation } from '../../hooks/use-confirm-password-validation';
import type { ChangePasswordFormModel } from '../../models/form.models';

// Note: "Wrong Code = URL updated manually" / Link Expired / Link already used: All will show same error
const RecoverPasswordLink = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const flow = searchParams.get('flow');
    const code = searchParams.get('code');
    const { setBackOverrideState } = useNavBar();

    const form = useForm<ChangePasswordFormModel>({
        validateInputOnBlur: true,
        initialValues: {
            password: '',
            confirmPassword: '',
        },
        schema: z.object({
            password: z
                .string({ message: t('passwordHelpText.enterPasswordSixChar') })
                .trim()
                .min(CONSTANTS.minimumPasswordLength, {
                    message: t('passwordHelpText.enterPasswordSixChar'),
                })
                .regex(/^\S*$/, {
                    message: t('passwordHelpText.containSpaces'),
                }),
        }),
    });
    const [loading, setLoading] = useState<boolean>(false);
    const [changePasswordSuccess, setChangePasswordSuccess] =
        useState<boolean>(false);
    const [settingsFlowId, setSettingsFlowId] = useState<string | undefined>(
        undefined
    );
    const [errorMessageType, setErrorMessageType] = useState<
        ErrorMessageTypes | undefined
    >();
    const isVerificationInitiated = useRef(false);
    const isRequiredParamsPassed = !!flow && !!code;
    const showGenericError =
        !isRequiredParamsPassed ||
        (errorMessageType &&
            errorMessageType != '400_PasswordSimilarAsIdError');
    const verifyCode = useCallback(async () => {
        setLoading(true);
        const {
            success: verifyRecoveryOtpSuccess,
            settingsFlowId,
            error: verifyRecoveryOtpError,
        } = await verifyRecoveryCodeLinkApi(code ?? '', flow ?? '');
        if (verifyRecoveryOtpSuccess) {
            setSettingsFlowId(settingsFlowId);
        }
        setLoading(false);
        setErrorMessageType(verifyRecoveryOtpError);
    }, []);

    useEffect(() => {
        if (flow && code && !isVerificationInitiated.current) {
            isVerificationInitiated.current = true;
            verifyCode().catch(console.error);
        }
    }, [verifyCode]);

    const ErrorMessage = errorMessageType ? ErrorMap[errorMessageType] : null;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onCreateClick = async (data: any) => {
        setErrorMessageType(undefined);
        const formData = data as ChangePasswordFormModel;
        setLoading(true);

        const {
            success: changePasswordSuccess,
            // session, // TODO: Redirect to correct myPHX page with session in it once its implemented
            error: changePasswordError,
        } = await recoverPasswordApi(formData.password, settingsFlowId ?? '');

        if (changePasswordSuccess) {
            setChangePasswordSuccess(true);
            setLoading(false);
            return;
        }
        setErrorMessageType(changePasswordError);
        setLoading(false);
    };

    useHotkeys([['Enter', () => form.onSubmit(onCreateClick)]]);

    useConfirmPasswordValidation(form);

    const onBackButtonClick = useCallback(() => navigate(-1), []);
    useEffect(() => {
        setBackOverrideState({ overrideFn: onBackButtonClick });
        return () => setBackOverrideState({ overrideFn: null });
    }, [onBackButtonClick]);

    return showGenericError ? (
        <GenericError
            title={t('recoverPasswordLink.expiredResetLink')}
            description={t('recoverPasswordLink.contactSupport')}
            illustration={<TimeExpiredIllustration />}
        />
    ) : (
        <PageLayout
            loadingOverlay={loading}
            header={errorMessageType && ErrorMessage && <ErrorMessage />}
            heading={
                <Stack gap="sm">
                    <Heading1>
                        {t('recoverPasswordLink.changePassword')}
                    </Heading1>
                    <Body1>{t('recoverPasswordLink.createNewPassword')}</Body1>
                </Stack>
            }
            footer={
                changePasswordSuccess ? (
                    // TODO: Needs to autoclose/autohide after 2000ms
                    <Notification
                        data-testid="password-success-snack-bar"
                        onClose={() => {
                            window.location.replace(
                                import.meta.env.VITE_MYPHX_UNAUTH_URL
                            );
                            setChangePasswordSuccess(false);
                        }}
                    >
                        {t('recoverPasswordLink.passwordUpdated')}
                    </Notification>
                ) : (
                    <SubmitButton form="recover-password-link-form">
                        {t('recoverPasswordLink.create')}
                    </SubmitButton>
                )
            }
        >
            <form
                id="recover-password-link-form"
                onSubmit={form.onSubmit(onCreateClick)}
            >
                <Stack gap="md">
                    <PasswordField form={form} page="RECOVER_PASSWORD_LINK" />
                    <ConfirmPasswordField
                        form={form}
                        page="RECOVER_PASSWORD_LINK"
                    />
                </Stack>
            </form>
        </PageLayout>
    );
};
export default RecoverPasswordLink;
