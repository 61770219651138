import { Center } from '@phx/design-system';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

const NotFoundPage = () => {
    const { t } = useTranslation();
    const { pathname } = useLocation();
    // instrument error state
    console.error('Navigated to unknown route: ', pathname);

    return <Center p="md">{t('notFound.pageNotFound')}</Center>;
};

export default NotFoundPage;
