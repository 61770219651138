import { Body1, Box, Button, Heading1, Stack } from '@phx/design-system';
import { NavigationBar } from '@phx/myphx-lib';
import { openTalkativeUI } from '@phx/talkative';
import type { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

export interface GenericErrorProps {
    onClick?: () => void;
    buttonLabel?: string;
    title?: string;
    description?: string;
    illustration?: ReactNode;
}

export const GenericError = (props: GenericErrorProps) => {
    const { onClick, buttonLabel, title, description, illustration } = props;
    const { t } = useTranslation();

    const headlineText = title || t('genericError.errorOccured');
    const descriptionText =
        description || t('genericError.contactSupportDescription');

    //NOTE: For some reason using <Error404Illustration /> imported from '@phx/myphx-lib' does not render the image properly
    const error404Illustration = (
        <img
            src={'/static/libraries/myphx-lib/illustrations/error404.svg'}
            alt={t('genericError.error404Alt')}
        />
    );

    return (
        <Box>
            <Box p="xxs">
                <NavigationBar />
            </Box>
            <Stack align="center" pt="lg" px="sm" gap="sm">
                {illustration ?? error404Illustration}
                <Heading1>{headlineText}</Heading1>
                <Body1 color="gray">{descriptionText}</Body1>
                <Button
                    size="lg"
                    fullWidth
                    data-testid="page-primary-action-button"
                    onClick={onClick ?? openTalkativeUI}
                >
                    {buttonLabel ?? t('genericError.contactSupportButtonLabel')}
                </Button>
            </Stack>
        </Box>
    );
};

export default GenericError;
