import type { RecoveryFlow, SettingsFlow, UiNode, UiText } from '@ory/client';

export type RecoveryFlowType = 'send-code' | 'verify-code' | 'change-password';

export const convertMessageIdToErrorType = (
    msgId: number,
    flow: RecoveryFlowType
) => {
    switch (msgId) {
        case 4000001:
            return flow == 'verify-code' // The request was submitted too often. Please request another code
                ? // After 5 attempts, 6th one gives a 303 redirect and the redirect's response has this code 4000001
                  // 6th attempt does not give this code but fails with a 403
                  '400_MaxRecoveryVerificationAttemptsReachedError'
                : '500_RecoverPasswordGenericError';
        case 4000005:
            return flow === 'change-password'
                ? '400_PasswordSimilarAsIdError' //The password can not be used because {reason}
                : '500_RecoverPasswordGenericError';
        case 4060006: //The recovery code is invalid or has already been used. Please try again.
            return '400_InvalidCode'; //NOTE: This also happens when user sent 2 email and tries with first email's code.
        case 4060005: //The recovery flow expired -0.02 minutes ago, please try again.
        case 4060004: //The recovery token is invalid or has already been used. Please retry the flow
        case 4060002: //The recovery flow reached a failure state and must be retried.
        case 4050001: //The settings flow expired -0.02 minutes ago, please try again. This will happen if user waited on change password page for too long
            return '500_RecoverPasswordGenericError';
    }
    return '500_RecoverPasswordGenericError';
};
export const getErrorMessageIdFromRecoveryFlow = (
    flow: RecoveryFlow
): number | undefined => {
    if (!flow.ui) {
        return undefined;
    }
    if (flow.ui.messages?.length) {
        const errors = flow.ui.messages.filter(
            (msg: UiText) => msg.type === 'error'
        );
        if (errors.length > 0) {
            return errors[0].id;
        }
    }
    const nodesWithMessage = flow.ui.nodes.filter(
        (node) => node.messages?.length > 0
    );
    // This is the case when user entered un-acceptable value in any properties
    nodesWithMessage.map((node: UiNode) => {
        const errors = node.messages.filter(
            (msg: UiText) => msg.type === 'error'
        );
        if (errors.length > 0) {
            return errors[0].id;
        }
    });
    return undefined;
};
export const getErrorMessageIdFromSettingsFlow = (
    flow: SettingsFlow
): number | undefined => {
    if (!flow.ui) {
        return undefined;
    }
    if (flow.ui.messages?.length) {
        const errors = flow.ui.messages.filter(
            (msg: UiText) => msg.type === 'error'
        );
        if (errors.length > 0) {
            return errors[0].id;
        }
    }
    const nodesWithMessage = flow.ui.nodes.filter(
        (node) => node.messages?.length > 0
    );
    // This is the case when user entered un-acceptable value in any properties
    let msgId;
    nodesWithMessage.map((node: UiNode) => {
        const errors = node.messages.filter(
            (msg: UiText) => msg.type === 'error'
        );
        if (errors.length > 0) {
            msgId = errors[0].id;
        }
    });
    return msgId;
};
