import {
    useAppInsightsContext,
    useTrackEvent,
} from '@microsoft/applicationinsights-react-js';
import { Anchor, Body1, Box, Flex, Stack, useNavBar } from '@phx/design-system';
import { useForm, useHotkeys } from '@phx/design-system/hooks';
import { PhoneNumberFormatter } from '@phx/utils';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { z } from 'zod';

import { loginApi } from '../../api/login.api';
import { sendVerificationCodeApi } from '../../api/send-verification-code.api';
import { PageLayout } from '../../components';
import {
    ErrorMap,
    type ErrorMessageTypes,
} from '../../components/alerts/errors/error-map';
import { PasswordField } from '../../components/form-fields/PasswordField';
import { PhoneNumberField } from '../../components/form-fields/PhoneNumberField';
import { SubmitButton } from '../../components/form-fields/SubmitButton';
import { CONSTANTS } from '../../constants';
import type { SigninFormModel } from '../../models/form.models';
import { routes } from '../../routes';
import type { VerifyAccountPageState } from '../verify-account/VerifyAccountPage';

export type SignInPageState = {
    backToSignUp?: boolean;
};

const Signin = () => {
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const location = useLocation();
    const state = location?.state as SignInPageState;
    const appInsights = useAppInsightsContext();
    const { setBackOverrideState } = useNavBar();
    const formRef = useRef<HTMLFormElement>(null);

    const trackForSubmit = useTrackEvent(
        appInsights,
        'SIGNIN_CONTINUE_CLICK',
        {}
    );
    const trackForSignup = useTrackEvent(
        appInsights,
        'SIGNIN_SIGNUP_CLICK',
        {}
    );
    const trackBackButton = useTrackEvent(appInsights, 'SIGNIN_BACK_CLICK', {});
    const trackForgotPwd = useTrackEvent(
        appInsights,
        'SIGNIN_FORGOT_PWD_CLICK',
        {}
    );
    const form = useForm<SigninFormModel>({
        initialValues: {
            phoneNumber: '',
            password: '',
        },
        validateInputOnBlur: true,
        schema: z.object({
            password: z.string().min(CONSTANTS.minimumPasswordLength, {
                message: t('formErrors.formErrorMessage.invalidPassword'),
            }),
            phoneNumber: z.string().regex(/^\(\d{3}\) \d{3}-\d{4}$/, {
                message: t('formErrors.formErrorMessage.invalidPhoneNumber'),
            }),
        }),
    });

    const [loading, setLoading] = useState<boolean>(false);
    const [errorMessageType, setErrorMessageType] = useState<
        ErrorMessageTypes | undefined
    >(undefined);

    const ErrorMessage = ErrorMap[errorMessageType ?? '500_GenericError'];

    // Read parameters passed in redirect
    const callBackUrl = searchParams.get('callback') || '/home';

    const onNavigateToSignUp = () => {
        trackForSignup({ message: 'Sign up link is clicked' });
        navigate(
            { pathname: routes.signUp, search: window.location.search },
            {
                state: { backToSignIn: true },
            }
        );
    };

    const onNavigateToForgotPassword = () => {
        trackForgotPwd({ message: 'Forgot password link is clicked' });
        navigate({
            pathname: routes.recoverSendCode,
            search: window.location.search,
        });
    };

    const onNavigateBack = useCallback(() => {
        trackBackButton({
            message: 'back button in sign-in screen is clicked',
        });
        if (state?.backToSignUp) {
            trackForSignup({
                message: 'Navigating back to sign-up screen from sign-in',
            });

            navigate(-1);
        } else {
            // go to unauth home page
            window.location.replace(import.meta.env.VITE_MYPHX_UNAUTH_URL);
        }
    }, [state]);

    useEffect(() => {
        setBackOverrideState({
            overrideFn: onNavigateBack,
        });

        return () => setBackOverrideState({ overrideFn: null });
    }, [onNavigateBack]);

    const onSigninClick = async (data: SigninFormModel) => {
        trackForSubmit({ message: 'Submit button is clicked' });
        const phoneNumber = data.phoneNumber;
        const password = data.password;
        const formattedPhoneNumber =
            PhoneNumberFormatter.formatForApi(phoneNumber);

        setErrorMessageType(undefined);
        setLoading(true);
        const { success, error, sessionId } = await loginApi(
            formattedPhoneNumber,
            password
        );
        if (success) {
            // Redirect back to sso page for getting this session converted to token
            const newUrl = `${callBackUrl}&session=${sessionId}&authMethod=${CONSTANTS.defaultAuth}`;
            window.location.replace(new URL(newUrl));
        } else if (error) {
            setErrorMessageType(error);
            setLoading(false);
        } else {
            // No error to show and not success in login --> Send OTP and redirect to verify
            trackForSubmit({
                message: 'Login is not succeeded so redirecting to OTP flow',
            });

            const { success: sendOtpSuccess, error: sendOtpError } =
                await sendVerificationCodeApi(formattedPhoneNumber);

            if (sendOtpSuccess) {
                const verifyPageState: VerifyAccountPageState = {
                    phoneNumber: formattedPhoneNumber,
                    password,
                    callBackUrl: callBackUrl ?? '',
                    isSignupFlow: false,
                };
                navigate(routes.verify, {
                    state: verifyPageState,
                });
            } else {
                trackForSubmit({
                    message:
                        'Login is not succeeded and redirecting to OTP also failed',
                });

                setErrorMessageType(sendOtpError);
                setLoading(false);
            }
        }
    };

    useHotkeys([
        [
            'Enter',
            (event: KeyboardEvent) => {
                event.preventDefault();
                event.stopPropagation();
                formRef.current?.requestSubmit();
            },
        ],
    ]);

    return (
        <PageLayout
            loadingOverlay={loading}
            title={t('signInPage.signIn')}
            footer={
                <Stack gap="sm">
                    <SubmitButton form="registration-form">
                        {t('common.continue')}
                    </SubmitButton>
                    <Flex gap="xxxs" justify="center">
                        <Body1>{t('signInPage.haveAccount')}</Body1>
                        <Anchor
                            onClick={onNavigateToSignUp}
                            data-testid="sign-up-link"
                        >
                            {t('signInPage.signUp')}
                        </Anchor>
                    </Flex>
                </Stack>
            }
            header={errorMessageType && ErrorMessage ? <ErrorMessage /> : null}
        >
            <form
                id="registration-form"
                onSubmit={form.onSubmit(onSigninClick)}
                ref={formRef}
            >
                <Stack gap="md">
                    <PhoneNumberField
                        form={form}
                        label={t('signInPage.mobileNumber')}
                        page="SIGNIN"
                    />

                    <PasswordField
                        form={form}
                        page="SIGNIN"
                        label={t('signInPage.password')}
                        showHelpText={false}
                    />

                    <Box>
                        <Anchor
                            data-testid="navigate-forgot-password-page"
                            onClick={onNavigateToForgotPassword}
                        >
                            {t('signInPage.forgotPassword')}
                        </Anchor>
                    </Box>
                </Stack>
            </form>
        </PageLayout>
    );
};

export default Signin;
