import { ApolloError } from '@apollo/client';

import type { ErrorMessageTypes } from '../components/alerts/errors/error-map';
import { CONSTANTS } from '../constants';
import type { SessionInformationApiResponse } from '../models/session-information.api.response';
import { LoginMutation } from '../mutation/login.mutation';
import { getTokenFromLocalStorageIfValid } from '../utils/get-token-from-local-storage';
import { setLocalStorageItem } from '../utils/local-storage/local-storage-operations';

import { getStatusCodeFromApolloError } from './get-status-code-from-apollo-error';
import { SsoApiClient } from './sso-api-client';

export const loginApi = async (
    phoneNumber: string,
    password: string
): Promise<SessionInformationApiResponse> => {
    let error: ErrorMessageTypes | undefined = '500_GenericError';

    const token = getTokenFromLocalStorageIfValid();
    const loginRequest = {
        identifier: phoneNumber,
        identifierType: CONSTANTS.otpContactType,
        password,
        deviceToken: token,
    };
    try {
        const { data } = await SsoApiClient.mutate({
            mutation: LoginMutation,
            errorPolicy: 'all',
            variables: {
                requestBody: loginRequest,
            },
        });
        if (data && data.loginResponse) {
            const { kratosSessionId } = data.loginResponse;
            setLocalStorageItem(CONSTANTS.sessionKey, kratosSessionId);
            return { success: true, sessionId: kratosSessionId };
        }
    } catch (err) {
        const loginError = err as ApolloError;
        error = getErrorMessageTypeForLoginApi(loginError);
    }
    return { success: false, error };
};

const getErrorMessageTypeForLoginApi = (
    error?: ApolloError
): ErrorMessageTypes | undefined => {
    if (error) {
        const statusCode = getStatusCodeFromApolloError(error);
        switch (statusCode) {
            case 400:
                return '400_InvalidCredentialsError';
            case 401: // 401 = token is expired, send OTP to user entered phone
            case 403: // 403 = token phone not matching with user entered phone, send OTP user entered phone
                return undefined;
        }
    }
    return '500_GenericError';
};
