import {
    useAppInsightsContext,
    useTrackEvent,
} from '@microsoft/applicationinsights-react-js';
import { useForm } from '@phx/design-system/hooks';

import type { CreateAccountFormModel } from '../models/form.models';

export const useSignupFormFieldProps = (
    form: ReturnType<typeof useForm<CreateAccountFormModel>>
) => {
    const aiCtx = useAppInsightsContext();

    const trackDOB =
        aiCtx && useTrackEvent(aiCtx, 'SIGNUP_DATEOFBIRTH_CLICK', {});

    const trackFullName = useTrackEvent(aiCtx, 'SIGNUP_FULLNAME_CLICK', {});

    const firstNameProps = form.getInputProps('firstName');
    firstNameProps.onFocus = () => {
        trackFullName({
            message: ' First name is clicked',
        });
        form.getInputProps('firstName').onFocus();
    };

    const lastNameProps = form.getInputProps('lastName');
    lastNameProps.onFocus = () => {
        trackFullName({
            message: ' Last name is clicked',
        });
        form.getInputProps('lastName').onFocus();
    };

    // These errors get aggregated under one "name" error
    delete firstNameProps.error;
    delete lastNameProps.error;

    const dobProps = form.getInputProps('dob');
    dobProps.onFocus = () => {
        trackDOB({
            message: 'DOB field is clicked',
        });
        form.getInputProps('dob').onFocus();
    };

    return { firstNameProps, lastNameProps, dobProps };
};
