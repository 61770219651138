import { gql } from '@apollo/client';

export const SendVerificationCodeMutation = gql`
    mutation SendVerificationCodeMutation {
        success(input: $requestBody)
            @rest(
                type: sendVerificationCode
                method: POST
                path: "/credentials/contact-otp"
            )
    }
`;
