import type { ApolloError, ServerError } from '@apollo/client';

import type { ErrorMessageTypes } from '../components/alerts/errors/error-map';
import type { GenericApiResponse } from '../models/generic.api.response';
import {
    type ChangePasswordErrorResponse,
    ChangePasswordMutation,
} from '../mutation/change-password.mutation';

import { getStatusCodeFromApolloError } from './get-status-code-from-apollo-error';
import { SsoApiClient } from './sso-api-client';

export const ChangePasswordApi = async (
    currentPassword: string,
    newPassword: string
): Promise<GenericApiResponse> => {
    let error: ErrorMessageTypes | undefined = '500_GenericError';

    const updatePasswordRequest = {
        currentPassword,
        newPassword,
    };

    try {
        const { data } = await SsoApiClient.mutate({
            mutation: ChangePasswordMutation,
            errorPolicy: 'all',
            variables: {
                requestBody: updatePasswordRequest,
            },
        });
        if (data && data.updatePasswordResponse) {
            return { success: true };
        }
    } catch (err) {
        const changePasswordError = err as ApolloError;
        error = getErrorMessageIdForChangePassword(changePasswordError);
    }
    return { success: false, error };
};

const getErrorMessageIdForChangePassword = (
    error?: ApolloError
): ErrorMessageTypes | undefined => {
    if (error) {
        const statusCode = getStatusCodeFromApolloError(error);
        switch (statusCode) {
            case 400: {
                const serverError = error.networkError as ServerError;
                const changePwdError =
                    serverError.result as ChangePasswordErrorResponse;
                if (
                    changePwdError.InvalidCurrentPassword &&
                    changePwdError.InvalidCurrentPassword.length > 0
                ) {
                    return '400_InvalidPasswordError';
                } else if (
                    changePwdError.InvalidNewPassword &&
                    changePwdError.InvalidNewPassword.length > 0
                ) {
                    return '400_PasswordSimilarAsIdError';
                }
                break;
            }
            case 401:
                return '500_GenericError'; // TODO: redirect back to myphx
        }
    }
    return '500_GenericError';
};
