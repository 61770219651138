import { gql } from '@apollo/client';

export const GetSessionInformationQuery = gql`
    query GetSessionInformationQuery {
        session @rest(type: "session", path: "/sessions/whoami") {
            id
            active
            expires_at
            authenticated_at
            issued_at
            identity {
                id
                state
                traits {
                    email
                    accountId
                    phoneNumber
                }
                devices {
                    id
                    ip_address
                    user_agent
                    location
                }
            }
        }
    }
`;
