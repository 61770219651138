import {
    Anchor,
    Body1,
    Flex,
    Heading1,
    Stack,
    useNavBar,
} from '@phx/design-system';
import { useForm, useHotkeys } from '@phx/design-system/hooks';
import { PhoneNumberFormatter } from '@phx/utils';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { z } from 'zod';

import { createAccountApi } from '../../api/create-account.api';
import { loginApi } from '../../api/login.api';
import { sendVerificationCodeApi } from '../../api/send-verification-code.api';
import { verifyCodeApi } from '../../api/verify-code.api';
import {
    ErrorMap,
    type ErrorMessageTypes,
} from '../../components/alerts/errors/error-map';
import { InfoMap } from '../../components/alerts/information/information-map';
import { SubmitButton } from '../../components/form-fields/SubmitButton';
import { VerificationCodeField } from '../../components/form-fields/VerificationCodeField';
import { PageLayout } from '../../components/layout/PageLayout';
import { CONSTANTS } from '../../constants';
import { telemetryInstance } from '../../instrumentation';
import type {
    ChallengeQuestionsFormModel,
    VerifyFormModel,
} from '../../models/form.models';

export type VerifyAccountPageState = {
    phoneNumber: string;
    password: string;
    isSignupFlow: boolean;
    callBackUrl: string;
    userId?: string; // Required in sign up flow
    email?: string; // Required in sign up flow
    firstName?: string; // Required in sign up flow
    lastName?: string; // Required in sign up flow
    dateOfBirth?: string; // Required in sign up flow
    address?: ChallengeQuestionsFormModel['address'] | null;
};

const VerifyAccount = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const form = useForm<VerifyFormModel>({
        validateInputOnBlur: true,
        schema: z.object({
            code: z
                .string({
                    message: t('formErrors.formErrorMessage.missingOtp'),
                })
                .length(6),
        }),
    });
    const { setBackOverrideState } = useNavBar();

    const state = location?.state as VerifyAccountPageState;

    const [loading, setLoading] = useState<boolean>(false);
    const [errorMessageType, setErrorMessageType] = useState<
        ErrorMessageTypes | undefined
    >();
    const [showResendMessage, setShowResendMessage] = useState<boolean>(false);

    const ErrorMessage = errorMessageType ? ErrorMap[errorMessageType] : null;
    const ResendMessage = showResendMessage ? InfoMap['CodeResentInfo'] : null;

    const onNavigateBack = useCallback(() => {
        navigate(-1);
    }, []);

    useEffect(() => {
        setBackOverrideState({
            overrideFn: onNavigateBack,
        });

        return () => setBackOverrideState({ overrideFn: null });
    }, [onNavigateBack]);

    const onVerifyCodeClick = async (formData: VerifyFormModel) => {
        telemetryInstance.logEvent({
            name: 'OTPVerifySubmitted',
            source: location.pathname,
        });
        setErrorMessageType(undefined);
        setShowResendMessage(false);
        setLoading(true);
        const { success, error } = await verifyCodeApi(
            state.phoneNumber,
            formData.code
        );

        if (success) {
            if (state.isSignupFlow) {
                // Call create account API
                const {
                    success: createSuccess,
                    error: createError,
                    sessionId,
                } = await createAccountApi(
                    state.firstName ?? '',
                    state.lastName ?? '',
                    state.email ?? '',
                    state.dateOfBirth ?? '',
                    state.phoneNumber ?? '',
                    state.password ?? '',
                    state.userId ?? '',
                    state.address ?? null
                );
                if (createSuccess) {
                    telemetryInstance.logEvent({
                        name: 'AccountCreated',
                        source: location.pathname,
                    });
                    // Redirect back to sso page for getting this session converted to token
                    window.location.replace(
                        new URL(
                            `${state.callBackUrl}&session=${sessionId}&authMethod=${CONSTANTS.defaultAuth}`
                        )
                    );
                } else {
                    setLoading(false);
                    setErrorMessageType(createError);
                    telemetryInstance.logError({
                        source: location.pathname,
                        exception: new Error(createError),
                    });
                }
            } else {
                // Call login api
                const {
                    success: loginSuccess,
                    error: loginError,
                    sessionId,
                } = await loginApi(
                    state.phoneNumber ?? '',
                    state.password ?? ''
                );

                if (loginSuccess) {
                    // Redirect back to sso page for getting this session converted to token
                    window.location.replace(
                        new URL(
                            `${state.callBackUrl}&session=${sessionId}&authMethod=${CONSTANTS.defaultAuth}`
                        )
                    );
                } else {
                    setLoading(false);
                    setErrorMessageType(loginError);
                    telemetryInstance.logError({
                        source: location.pathname,
                        exception: new Error(loginError),
                    });
                }
            }
        } else {
            setLoading(false);
            setErrorMessageType(error);
            telemetryInstance.logError({
                source: location.pathname,
                exception: new Error(error),
            });
        }
    };

    const onSendCodeClick = async () => {
        setLoading(true);
        const { success, error } = await sendVerificationCodeApi(
            state.phoneNumber
        );
        setLoading(false);
        if (success) {
            setErrorMessageType(undefined);
            setShowResendMessage(true);
        } else {
            setErrorMessageType(error);
            setShowResendMessage(false);
            telemetryInstance.logError({
                source: location.pathname,
                exception: new Error(error),
            });
        }
    };

    useHotkeys([['Enter', () => form.onSubmit(onVerifyCodeClick)]]);

    return (
        <PageLayout
            loadingOverlay={loading}
            header={
                <>
                    {errorMessageType && ErrorMessage && <ErrorMessage />}
                    {showResendMessage && ResendMessage && <ResendMessage />}
                </>
            }
            heading={
                <Stack gap="sm">
                    <Heading1>
                        {t('verifyAccountPage.oneTimeVerificationCode')}
                    </Heading1>
                    {state?.phoneNumber ? (
                        <Body1>
                            {t('verifyAccountPage.sixDigitVerificationCode', {
                                phoneNumber: PhoneNumberFormatter.formatForUI(
                                    state.phoneNumber
                                ),
                            })}
                        </Body1>
                    ) : null}
                </Stack>
            }
            footer={
                <SubmitButton form="verification-code-form">
                    {t('verifyAccountPage.verify')}
                </SubmitButton>
            }
        >
            <form
                id="verification-code-form"
                onSubmit={form.onSubmit(onVerifyCodeClick)}
            >
                {/* Do not remove this dummy text. Enter key press does not work as 
                expected when there is only one input in form  */}
                <input type="text" hidden={true} name={'dummy'} />
                <VerificationCodeField form={form} page="VERIFY_ACCOUNT" />
            </form>
            <Flex justify="left">
                <Body1>
                    {t('verifyAccountPage.receiveCode')}
                    <Anchor onClick={onSendCodeClick}>
                        {t('verifyAccountPage.resend')}
                    </Anchor>
                </Body1>
            </Flex>
        </PageLayout>
    );
};
export default VerifyAccount;
