import { Overlay } from '@phx/design-system';
import {
    type InitiateLoginChallengeProps,
    initiateLoginChallenge,
} from '@phx/front-end-auth';
import { useCallback, useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';

import { CONSTANTS } from '../../constants';
import { getLocalStorageItem } from '../../utils/local-storage/local-storage-operations';

const InitiateProfileUpdate = () => {
    const [searchParams] = useSearchParams();
    const isGenerateLoginChallengeInitiated = useRef(false);
    const callback = searchParams.get('callback');
    const loginChallenge = searchParams.get('login_challenge');

    const ssoUrl = import.meta.env.VITE_TOKEN_AUTH_URL;
    const redirectUri = import.meta.env.VITE_REDIRECT_URI;
    const ssoClientId = import.meta.env.VITE_TOKEN_CLIENT_ID;

    const generateLoginChallenge = useCallback(async () => {
        const props: InitiateLoginChallengeProps = {
            currentUrl: `/profile?callback=${callback}`,
            flow: 5,
            mustReauthenticate: false,
            ssoUrl,
            ssoClientId,
            redirectUri,
        };
        await initiateLoginChallenge(props);
    }, []);

    useEffect(() => {
        if (!loginChallenge && !isGenerateLoginChallengeInitiated.current) {
            // Login Challenge does not exists, generate it
            isGenerateLoginChallengeInitiated.current = true;
            generateLoginChallenge().catch(console.error);
        }
    }, [generateLoginChallenge]);

    if (loginChallenge) {
        const session = getLocalStorageItem(CONSTANTS.sessionKey);
        if (!session) {
            window.location.replace(
                new URL(
                    `${window.location.origin}/sign-in?callback=${callback}`
                )
            );
        } else {
            // Redirect back to sso page for getting this session converted to token
            const newUrl = `${callback}&session=${session}&authMethod=${CONSTANTS.defaultAuth}`;
            window.location.replace(new URL(newUrl));
        }
    }

    return <Overlay visible loader fullScreen />;
};

export default InitiateProfileUpdate;
