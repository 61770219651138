import type { ApolloError, ServerError } from '@apollo/client';

import type { ErrorMessageTypes } from '../components/alerts/errors/error-map';
import type { GenericApiResponse } from '../models/generic.api.response';
import {
    type AccountErrorResponse,
    type VerifyAccountModel,
    VerifyAccountMutation,
    type VerifyAccountRequest,
    isGeneralAccountErrorResponse,
} from '../mutation/verify-account.mutation';

import { getAccountErrorFromApolloError } from './get-account-error';
import { SsoApiClient } from './sso-api-client';

export type VerifyAccountApiResponse = GenericApiResponse & {
    userId?: string;
    tryAdditionalInfo?: boolean;
};

export interface VerifyAccountParams {
    firstName: string;
    lastName: string;
    email: string;
    dateOfBirth: string;
    phoneNumber: string;
    masterId?: string;
    memberId?: string;
    address?: VerifyAccountModel['address'];
}

export const verifyAccountApi = async ({
    firstName,
    lastName,
    email,
    dateOfBirth,
    phoneNumber,
    masterId,
    memberId,
    address,
}: VerifyAccountParams): Promise<VerifyAccountApiResponse> => {
    let error: ErrorMessageTypes = '500_GenericError';
    let tryAdditionalInfo = false;

    const verifyAccountRequest: VerifyAccountRequest = {
        patient: {
            firstName,
            lastName,
            dateOfBirth,
            phoneNumber,
            email,
        },
    };

    if (masterId && masterId.length) {
        verifyAccountRequest.patientProfile = `patient/${masterId}`;
    } else if (memberId) {
        verifyAccountRequest.memberIdentifier = memberId;
    }

    if (address) {
        verifyAccountRequest.patient.address = address;
    }

    try {
        const { data } = await SsoApiClient.mutate({
            mutation: VerifyAccountMutation,
            errorPolicy: 'all',
            variables: {
                requestBody: verifyAccountRequest,
            },
        });

        if (data?.verifyAccountResponse?.success) {
            const result: VerifyAccountApiResponse = {
                success: true,
                userId: data.verifyAccountResponse.masterAccountId,
            };

            if (
                typeof data.verifyAccountResponse.tryAdditionalInfo !==
                'undefined'
            ) {
                result.tryAdditionalInfo =
                    data.verifyAccountResponse.tryAdditionalInfo;
            }

            return result;
        }
    } catch (err) {
        const verifyAccountError = err as ApolloError;
        error = verifyAccountError
            ? getAccountErrorFromApolloError(verifyAccountError)
            : '500_GenericError';

        let accountError: AccountErrorResponse | null = null;
        if (typeof verifyAccountError?.networkError !== 'undefined') {
            const serverError = verifyAccountError.networkError as ServerError;
            accountError = serverError.result as AccountErrorResponse;
        }

        if (
            !isGeneralAccountErrorResponse(accountError) &&
            Array.isArray(accountError?.TryAdditionalInfo) &&
            accountError!.TryAdditionalInfo.length > 0 &&
            accountError!.TryAdditionalInfo[0] === 'true'
        ) {
            tryAdditionalInfo = true;
        }
    }

    return { success: false, error, tryAdditionalInfo };
};
