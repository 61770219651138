import { ApolloError, type ServerError } from '@apollo/client';

export const getStatusCodeFromApolloError = (
    error: ApolloError
): number | undefined => {
    if (error && error.networkError) {
        const serverError = error.networkError as ServerError;
        if (!serverError.statusCode) {
            return undefined;
        }
        return serverError.statusCode;
    }
    return undefined;
};
