import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client';
import { RestLink } from 'apollo-link-rest';

const restLink = new RestLink({
    uri: import.meta.env.VITE_KRATOS_API_URL,
    credentials: 'include',
});

export const KratosApiClient = new ApolloClient({
    cache: new InMemoryCache(),
    link: restLink as unknown as ApolloLink,
});

export default KratosApiClient;
