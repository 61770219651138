export const routes = {
    root: '/',
    update: '/profile/update',
    tokenCallback: '/sso/token/callback',
    recoverSendCode: '/recover-send-code',
    recoverVerifyCode: '/recover-verify-code',
    recover: '/recover',
    recoverLink: '/recovery',
    signIn: '/sign-in',
    signUp: '/sign-up',
    signUpChallengeQuestions: '/more-info',
    signUpError: '/sign-up/error',
    verify: '/verify',
    profile: '/profile',
    sso: '/sso',
    wildcard: '*',
};
