import {
    useAppInsightsContext,
    useTrackEvent,
} from '@microsoft/applicationinsights-react-js';
import { Alert, Anchor } from '@phx/design-system';
import { openTalkativeUI } from '@phx/talkative';
import { Trans } from 'react-i18next';

export const MismatchedInformationError = () => {
    const appInsights = useAppInsightsContext();
    const trackContactUs = useTrackEvent(appInsights, 'CONTACT_US_CLICK', {});

    return (
        <Alert data-testid="mismatched-information-error" severity="error">
            <Trans i18nKey="formErrors.mismatchedInfoError">
                One or more entered information do not match our records. Please
                try again. If problem persists, please{' '}
                <Anchor
                    onClick={() => {
                        trackContactUs({
                            message:
                                'Contact us in mismatch error message is clicked',
                        });
                        openTalkativeUI();
                    }}
                >
                    contact us
                </Anchor>
                .
            </Trans>
        </Alert>
    );
};
