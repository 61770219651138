import {
    useAppInsightsContext,
    useTrackEvent,
} from '@microsoft/applicationinsights-react-js';
import { TextInput } from '@phx/design-system';
import { useForm } from '@phx/design-system/hooks';
import { useTranslation } from 'react-i18next';

export type FormWithVerificationCode = { code: string };
export type VerificationCodeFieldProps<
    T extends FormWithVerificationCode = FormWithVerificationCode,
> = {
    form: ReturnType<typeof useForm<T>>;
    page: string;
};

export const VerificationCodeField = <
    T extends FormWithVerificationCode = FormWithVerificationCode,
>({
    form,
    page,
}: VerificationCodeFieldProps<T>) => {
    const { t } = useTranslation();
    const appInsights = useAppInsightsContext();

    const track = useTrackEvent(appInsights, `${page}_OTP_CLICK`, {});

    const props = form.getInputProps('code');
    props.onFocus = () => {
        track({ message: 'one-time password field is clicked' });
        form.getInputProps('code').onFocus();
    };

    return (
        <TextInput
            key={form.key('code')}
            name="code"
            label={t('common.formFields.verificationCode.label')}
            placeholder={t('common.formFields.verificationCode.placeholder')}
            required
            {...props}
        />
    );
};
