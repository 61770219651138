import { gql } from '@apollo/client';

export const UpdatePasswordMutation = gql`
    mutation UpdatePasswordMutation {
        updatePasswordResponse(input: $requestBody, id: $flowId)
            @rest(
                type: updatePasswordResponse
                method: POST
                path: "/self-service/settings?flow={args.id}"
            ) {
            id
            expires_at
            issued_at
            return_to
            ui {
                nodes {
                    group
                    attributes {
                        name
                        onclick
                        type
                        value
                    }
                    messages {
                        context
                        id
                        text
                        type
                    }
                    meta {
                        label
                    }
                    type
                }
            }
        }
    }
`;
