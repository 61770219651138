import { decodeJwt } from 'jose';
import { CONSTANTS } from './helpers/constants';
import { getAccessToken, removeLocalStorageItem, } from './helpers/token.service';
export const validateAndGetAccessToken = () => {
    const tokenKey = CONSTANTS.tokenKey;
    const token = getAccessToken();
    if (token) {
        const decodedToken = decodeJwt(token);
        const tokenExpiryDate = decodedToken.exp;
        const isTokenValid = tokenExpiryDate && 1000 * tokenExpiryDate > Date.now();
        if (isTokenValid) {
            return token;
        }
        else {
            removeLocalStorageItem(tokenKey);
        }
    }
    return undefined;
};
