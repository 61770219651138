import {
    useAppInsightsContext,
    useTrackEvent,
} from '@microsoft/applicationinsights-react-js';
import {
    Alert,
    Anchor,
    Body1,
    Button,
    Heading1,
    Stack,
} from '@phx/design-system';
import { openTalkativeUI } from '@phx/talkative';
import type { MouseEvent } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { PageLayout } from '../../components/layout/PageLayout';

const AccountCreationError = () => {
    const { t } = useTranslation();
    const appInsights = useAppInsightsContext();

    const trackContactUsButton = useTrackEvent(
        appInsights,
        'ADDITIONALASSISTANCE_CONTACTUS_BUTTON_CLICK',
        {}
    );

    const trackContactUsLink = useTrackEvent(
        appInsights,
        'ADDITIONALASSISTANCE_CONTACTUS_LINK_CLICK',
        {}
    );

    const handleContactButtonClick = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        e.stopPropagation();

        trackContactUsButton({});
        openTalkativeUI();
    };

    const handleContactLinkClick = (e: MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        e.stopPropagation();

        trackContactUsLink({});
        openTalkativeUI();
    };

    return (
        <PageLayout
            header={
                <Alert data-testid="invalid-code-error" severity="error">
                    <Trans i18nKey="accountCreationError.header">
                        {'Unable to create your account. Please '}
                        <Anchor onClick={handleContactLinkClick} color="error">
                            {'contact us'}
                        </Anchor>
                        {'.'}
                    </Trans>
                </Alert>
            }
            heading={
                <Stack gap="sm">
                    <Heading1>
                        {t('accountCreationError.additionalAssistance')}
                    </Heading1>
                    <Stack gap="xs">
                        <Body1>{t('accountCreationError.customerCare')}</Body1>
                        <Body1>
                            {t('accountCreationError.assistEfficiently')}
                        </Body1>
                    </Stack>
                </Stack>
            }
            footer={
                <Button fullWidth onClick={handleContactButtonClick}>
                    {t('accountCreationError.contactUsButton')}
                </Button>
            }
        />
    );
};

export default AccountCreationError;
