import { Overlay } from '@phx/design-system';
import {
    type GetAuthTokenFromCodeProps,
    getAuthTokensFromCode,
} from '@phx/front-end-auth';
import { useLayoutEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { GenericError } from '../../components/generic-error/GenericError';

const InitiateTokenCallback = () => {
    const [searchParams] = useSearchParams();
    const [errorMessage, setErrorMessage] = useState<string>('');
    const navigate = useNavigate();
    const isGenerateTokenInitiated = useRef(false);

    const redirectUri = import.meta.env.VITE_REDIRECT_URI;
    const ssoClientId = import.meta.env.VITE_TOKEN_CLIENT_ID;
    const ssoTokenApi = import.meta.env.VITE_TOKEN_API_URL;

    useLayoutEffect(() => {
        async function fetchToken() {
            const successCode = searchParams.get('code');
            const base64StateInUrl = searchParams.get('state');
            const authError = searchParams.get('error');
            const authErrorDescription = searchParams.get('error_description');

            if (successCode && base64StateInUrl) {
                const authTokenParams: GetAuthTokenFromCodeProps = {
                    responseCode: successCode,
                    base64StateInUrl,
                    ssoClientId,
                    redirectUri,
                    ssoTokenApi,
                };

                const { error, errorDescription, nextUrl } =
                    await getAuthTokensFromCode(authTokenParams);
                if (nextUrl) {
                    navigate(nextUrl);
                }

                if (error) {
                    setErrorMessage(`${error} ${errorDescription ?? ''}`);
                }
            } else if (authError) {
                setErrorMessage(`${authError} ${authErrorDescription ?? ''}`);
            } else {
                setErrorMessage('This url is invalid.');
            }
        }

        if (!isGenerateTokenInitiated.current) {
            isGenerateTokenInitiated.current = true;
            fetchToken();
        }
    }, []);

    if (errorMessage) {
        return <GenericError description={errorMessage} />;
    }
    return <Overlay visible loader fullScreen />;
};

export default InitiateTokenCallback;
