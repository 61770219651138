import { ApolloError } from '@apollo/client';

import type { ErrorMessageTypes } from '../components/alerts/errors/error-map';
import { CONSTANTS } from '../constants';
import type { GenericApiResponse } from '../models/generic.api.response';
import { VerifyCodeMutation } from '../mutation/verify-code.mutation';
import { setLocalStorageItem } from '../utils/local-storage/local-storage-operations';

import { getStatusCodeFromApolloError } from './get-status-code-from-apollo-error';
import SsoApiClient from './sso-api-client';

export const verifyCodeApi = async (
    phoneNumber: string,
    code: string
): Promise<GenericApiResponse> => {
    let error: ErrorMessageTypes = '500_GenericError';
    const verifyCodeRequest = {
        contactValue: phoneNumber,
        confirmationCode: code,
        source: CONSTANTS.otpVerifySource,
    };
    try {
        const { data } = await SsoApiClient.mutate({
            mutation: VerifyCodeMutation,
            errorPolicy: 'all',
            variables: {
                requestBody: verifyCodeRequest,
            },
        });
        if (data && data.token) {
            setLocalStorageItem(CONSTANTS.tokenKey, data.token);
            return { success: true };
        }
    } catch (err) {
        const verifyCodeError = err as ApolloError;
        error = getErrorMessageTypeForVerifyCodeApi(verifyCodeError);
    }
    return { success: false, error };
};

const getErrorMessageTypeForVerifyCodeApi = (
    error?: ApolloError
): ErrorMessageTypes => {
    if (error) {
        const statusCode = getStatusCodeFromApolloError(error);
        switch (statusCode) {
            case 400:
                return '400_InvalidCode';
            case 429:
                return '429_MaxAttemptReached';
        }
    }
    return '500_GenericError';
};
