export const getLongestCommonSubString = (str1: string, str2: string) => {
    const str1InLowerCase = str1.toLowerCase();
    const str2InLowerCase = str2.toLowerCase();

    const tempArr = Array(str2InLowerCase.length + 1).fill(0);
    let longestLength = 0;

    for (let i = 1; i <= str1InLowerCase.length + 1; i++) {
        for (let j = str2InLowerCase.length; j > 0; j--) {
            if (str1InLowerCase[i - 1] === str2InLowerCase[j - 1]) {
                tempArr[j] = tempArr[j - 1] + 1;
            } else {
                tempArr[j] = 0;
            }

            if (longestLength < tempArr[j]) {
                longestLength = tempArr[j];
            }
        }
    }
    return longestLength;
};
