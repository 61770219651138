import { Alert } from '@phx/design-system';
import { useTranslation } from 'react-i18next';

export const InvalidCodeError = () => {
    const { t } = useTranslation();
    return (
        <Alert data-testid="invalid-code-error" severity="error">
            {t('formErrors.invalidCodeError.tryAgain')}
        </Alert>
    );
};
