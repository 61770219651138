import { Fragment } from 'react';

import { AccountExistsError } from './AccountExists.error';
import { BadPhoneNumberError } from './BadPhoneNumber.error';
import { EmailExistsError } from './EmailExists.error';
import { GenericMessageError } from './GenericMessage.error';
import { InvalidCodeError } from './InvalidCode.error';
import { InvalidCredentialsError } from './InvalidCredentials.error';
import { InvalidPasswordError } from './InvalidPassword.error';
import { MaxAttemptsReachedError } from './MaxAttemptsReached.error';
import { MaxRecoveryVerificationAttemptsReachedError } from './MaxRecoveryVerificationAttemptsReached.error';
import { MismatchedInformationError } from './MismatchedInformation.error';
import { MultiplePatientsExistError } from './MultiplePatientsExist.error';
import { PasswordSimilarAsIdError } from './PasswordSimilarAsId.error';
import { PhoneEmailExistsError } from './PhoneEmailExists.error';
import { PhoneExistsError } from './PhoneExists.error';
import { RecoverPasswordGenericMessageError } from './RecoverPasswordGenericError';
import { RecoverPasswordLinkError } from './RecoverPasswordLinkError';

export type ErrorMessageTypes =
    | '400_InvalidCode'
    | '400_BadPhoneNumberError'
    | '400_InvalidCredentialsError'
    | '400_MismatchedInformationError'
    | '400_PhoneEmailExistsError'
    | '400_EmailExistsError'
    | '400_PhoneExistsError'
    | '400_AccountExistsError'
    | '400_PasswordSimilarAsIdError'
    | '400_InvalidPasswordError'
    | '400_MaxRecoveryVerificationAttemptsReachedError'
    | '409_MultiplePatientsFoundForDemographic'
    | '429_MaxAttemptReached'
    | '500_GenericError'
    | '500_RecoverPasswordGenericError'
    | '500_RecoverPasswordLinkError'
    | '500_MultiplePatientsFoundError';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ErrorMap: Record<ErrorMessageTypes, React.FC<any>> = {
    '400_InvalidCode': InvalidCodeError,
    '400_BadPhoneNumberError': BadPhoneNumberError,
    '400_InvalidCredentialsError': InvalidCredentialsError,
    '400_MismatchedInformationError': MismatchedInformationError,
    '400_PhoneEmailExistsError': PhoneEmailExistsError,
    '400_EmailExistsError': EmailExistsError,
    '400_PhoneExistsError': PhoneExistsError,
    '400_AccountExistsError': AccountExistsError,
    '400_PasswordSimilarAsIdError': PasswordSimilarAsIdError,
    // This is a non-recoverable error, so rather than displaying an alert, we will be redirecting the user to a new page
    '409_MultiplePatientsFoundForDemographic': Fragment,
    '429_MaxAttemptReached': MaxAttemptsReachedError,
    '400_MaxRecoveryVerificationAttemptsReachedError':
        MaxRecoveryVerificationAttemptsReachedError,
    '500_RecoverPasswordGenericError': RecoverPasswordGenericMessageError,
    '500_RecoverPasswordLinkError': RecoverPasswordLinkError,
    '500_GenericError': GenericMessageError,
    '400_InvalidPasswordError': InvalidPasswordError,
    '500_MultiplePatientsFoundError': MultiplePatientsExistError,
};
