import type { useForm } from '@phx/design-system/hooks';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { CONSTANTS } from '../constants';
import { getLongestCommonSubString } from '../utils/get-longest-substring';

export type FormWithPassword = {
    password: string;
    email?: string;
    phoneNumber?: string;
};

export const usePasswordValidation = <
    T extends FormWithPassword = FormWithPassword,
>(
    form: ReturnType<typeof useForm<T>>
) => {
    const { t } = useTranslation();

    const checkPassword = useCallback(
        (password: string, email?: string, phoneNumber?: string) => {
            if (!form.isTouched('password')) {
                return;
            }

            if (password.length < CONSTANTS.minimumPasswordLength) {
                form.setFieldError(
                    'password',
                    t('passwordHelpText.enterPasswordSixChar')
                );
                return;
            }

            if (/\s/.test(password)) {
                form.setFieldError(
                    'password',
                    t('passwordHelpText.containSpaces')
                );
                return;
            }

            const isSimilarToEmail = email
                ? getLongestCommonSubString(email, password) >= 4
                : false;

            const isSimilarToPhoneNumber = phoneNumber
                ? getLongestCommonSubString(
                      phoneNumber.replace(/[^\d+]+/g, '').trim(),
                      password
                  ) >= 4
                : false;

            if (isSimilarToEmail || isSimilarToPhoneNumber) {
                form.setFieldError(
                    'password',
                    t('passwordHelpText.similarToEmailOrMobile')
                );
                return;
            }

            form.clearFieldError('password');
        },
        [form]
    );

    useEffect(() => {
        const { email, password, phoneNumber } = form.values;
        checkPassword(password, email, phoneNumber);
    }, [form.values.email, form.values.password, form.values.phoneNumber]);

    return { checkPassword };
};
