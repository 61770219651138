import { gql } from '@apollo/client';

export const VerifyCodeMutation = gql`
    mutation VerifyCodeMutation {
        token(input: $requestBody)
            @rest(
                type: confirmVerificationCode
                method: PUT
                path: "/credentials/contact-otp/confirm"
            )
    }
`;
