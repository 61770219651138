// src/helpers/get-talkative-elements.ts
var getTalkativeEngageElement = () => document.getElementById("talkative-engage");
var getTalkativeApp = () => getTalkativeEngageElement()?.shadowRoot?.querySelector("#app") ?? null;
var getOpenWidgetButton = () => getTalkativeApp()?.querySelector(".fixed")?.querySelector("button") ?? null;
var openTalkativeUI = () => {
  window.talkativeApi.ui.show();
};

// src/talkative-config.ts
var hideButton = () => {
  setTimeout(() => {
    const button = getOpenWidgetButton();
    if (button) {
      button.style.display = "none";
    }
    const app = getTalkativeEngageElement();
    if (app) {
      app.style.zIndex = "auto";
    }
  }, 0);
};
var talkativeConfig = {
  events: {
    ready() {
      hideButton();
      window.talkativeApi.ui.hide();
    },
    visibilityChange() {
      hideButton();
    },
    completeInteraction() {
      window.talkativeApi.ui.hide();
    }
  }
};

// src/TalkativeInitializer.tsx
window.talkativeCustomConfig = talkativeConfig;
var TalkativeInitializer = () => {
  return null;
};
document.addEventListener("DOMContentLoaded", () => {
  const tlkStyle = document.createElement("style");
  document.head.appendChild(tlkStyle);
  tlkStyle.appendChild(document.createTextNode("#talkative-engage {position: absolute; z-index: -1;}"));
  const appUrl = new URL("https://us.engage.app/api/ecs/v1/loader/93c017d2-a496-4b71-9ef4-25789b0c867c.js");
  appUrl.searchParams.append("path", window.location.origin + window.location.pathname);
  const selectedVersion = new URLSearchParams(window.location.search).get("ecsSelectedVersion");
  if (selectedVersion) {
    appUrl.searchParams.append("selectedVersion", selectedVersion);
  }
  import(/* @vite-ignore */ appUrl.href).catch((error) => {
    console.log(error?.message);
  });
});
// src/TalkativeWidget.tsx
import { Anchor, Body1, Flex, IconSupportAgent } from "@phx/design-system";
import { useTranslation } from "react-i18next";
import { jsx, jsxs } from "react/jsx-runtime";
var TalkativeWidget = () => {
  const { t } = useTranslation("tlk");
  return /* @__PURE__ */ jsxs(Flex, {
    align: "center",
    gap: "xxxs",
    children: [
      /* @__PURE__ */ jsx(IconSupportAgent, {
        color: "secondary"
      }),
      /* @__PURE__ */ jsx(Body1, {
        children: t("talkativeWidget.needHelp")
      }),
      /* @__PURE__ */ jsx(Anchor, {
        onClick: openTalkativeUI,
        children: t("talkativeWidget.contactUs")
      })
    ]
  });
};
export {
  openTalkativeUI,
  TalkativeWidget,
  TalkativeInitializer
};

//# debugId=9F7600AFFF3F780864756E2164756E21
