import { gql } from '@apollo/client';

export const GetSettingsFlowByIdQuery = gql`
    query GetSettingsFlowByIdQuery {
        settingsFlow(flowId: $flowId)
            @rest(
                type: "settingsFlow"
                path: "/self-service/settings/flows?id={args.flowId}"
            ) {
            id
            type
            expires_at
            issued_at
            request_url
            return_to
            ui {
                nodes {
                    group
                    attributes {
                        name
                        onclick
                        type
                        value
                    }
                    messages {
                        context
                        id
                        text
                        type
                    }
                    meta {
                        label
                    }
                    type
                }
                messages {
                    context
                    id
                    text
                    type
                }
            }
            identity {
                id
                state
                traits {
                    email
                    accountId
                    phoneNumber
                }
                devices {
                    id
                    ip_address
                    user_agent
                    location
                }
            }
            issued_at
            request_url
            return_to
            type
            state
        }
    }
`;
