import { CodeResentInfo } from './CodeResent.info';
import { RecoveryCodeResentInfo } from './RecoveryCodeResent.info';

export type InfoMessageTypes = 'CodeResentInfo' | 'RecoveryCodeResentInfo';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const InfoMap: Record<InfoMessageTypes, React.FC<any>> = {
    CodeResentInfo: CodeResentInfo,
    RecoveryCodeResentInfo: RecoveryCodeResentInfo,
};
