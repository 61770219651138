import { generatePkceSet } from './helpers/auth.helper';
import { CONSTANTS } from './helpers/constants';
import { setLocalStorageItem } from './helpers/token.service';
export var Flow;
(function (Flow) {
    Flow[Flow["Deeplink"] = 1] = "Deeplink";
    Flow[Flow["Employer"] = 2] = "Employer";
    Flow[Flow["CreateAccount"] = 3] = "CreateAccount";
    Flow[Flow["SignIn"] = 4] = "SignIn";
    Flow[Flow["UpdateProfile"] = 5] = "UpdateProfile";
})(Flow || (Flow = {}));
export const initiateLoginChallenge = async ({ context, currentUrl, flow, employer, mustReauthenticate = false, ssoUrl, ssoClientId, redirectUri, }) => {
    const { challenge, codeVerifier } = await generatePkceSet();
    const stateKey = CONSTANTS.csrfProtectionState;
    const stateValue = {
        ...(context && { context }),
        ...(employer && { employer }),
        flow,
        currentUrl,
        codeVerifier,
        state: stateKey,
        mustReauthenticate,
    };
    const payload = window.btoa(JSON.stringify(stateValue));
    setLocalStorageItem(stateKey, payload);
    const codeVerifierLocalStateKeyName = `${stateKey}${CONSTANTS.pkceCodeVerifierPrefix}`;
    setLocalStorageItem(codeVerifierLocalStateKeyName, codeVerifier);
    window.location.replace(new URL(`${ssoUrl}?client_id=${ssoClientId}&redirect_uri=${redirectUri}&response_type=code&state=${payload}&code_challenge_method=S256&code_challenge=${challenge}`));
};
