import { Alert, Anchor } from '@phx/design-system';
import { openTalkativeUI } from '@phx/talkative';
import { Trans } from 'react-i18next';

export const RecoverPasswordLinkError = () => {
    return (
        <Alert data-testid="recover-link-error" severity="error">
            <Trans i18nKey="formErrors.recoverPasswordLinkError">
                Either the link is used already or expired or tampered with. To
                get a new link please{' '}
                <Anchor onClick={openTalkativeUI}>contact us</Anchor>.
            </Trans>
        </Alert>
    );
};
