import { Button } from '@phx/design-system';
import type { PropsWithChildren as PWC } from 'react';

export type SubmitButtonProps = {
    disabled?: boolean;
    form: string;
};

export const SubmitButton = ({
    children,
    disabled = false,
    form,
}: PWC<SubmitButtonProps>) => {
    return (
        <Button
            disabled={disabled}
            form={form}
            type="submit"
            size="lg"
            fullWidth
        >
            {children}
        </Button>
    );
};
