import {
    AppShell,
    Heading1,
    Overlay,
    Stack,
    useNavBar,
} from '@phx/design-system';
import {
    type PropsWithChildren as PWC,
    type ReactNode,
    useCallback,
    useMemo,
} from 'react';
import { useLocation } from 'react-router-dom';

export interface PageLayoutProps {
    loadingOverlay?: ReactNode;
    header?: ReactNode;
    heading?: ReactNode;
    title?: string;
    footer?: ReactNode;
    homeUrl?: string;
}

export const PageLayout = ({
    loadingOverlay,
    header,
    heading,
    title,
    footer,
    children,
    homeUrl = import.meta.env.VITE_MYPHX_UNAUTH_URL,
}: PWC<PageLayoutProps>) => {
    const { pathname, key } = useLocation();
    const { backOverrideState } = useNavBar();

    const handleBack = useMemo(() => {
        // Explanation here https://github.com/remix-run/history/blob/main/docs/api-reference.md#locationkey
        const isInitialLocation = key === 'default';

        if (backOverrideState.overrideFn) return backOverrideState.overrideFn;
        if (isInitialLocation) return;

        return;
    }, [pathname, backOverrideState.overrideFn]);

    const goHome = useCallback(() => {
        window.location.replace(homeUrl);
    }, [homeUrl]);

    return (
        <AppShell
            header={header}
            navBarProps={{
                logoUrl:
                    '/static/libraries/myphx-lib/application-header/myprescryptive-logo.png',
                onBackButtonClick: handleBack,
                onLogoClick: goHome,
            }}
        >
            {loadingOverlay ? <Overlay visible loader fullScreen /> : null}
            <Stack gap="lg" flex="1">
                <Stack gap="lg" flex={{ base: '1', sm: 'unset' }}>
                    {heading ? heading : <Heading1>{title}</Heading1>}
                    {children}
                </Stack>
                {footer}
            </Stack>
        </AppShell>
    );
};

export default PageLayout;
