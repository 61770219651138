import type { ApolloError, ServerError } from '@apollo/client';
import type { RecoveryFlow } from '@ory/client';

import type { ErrorMessageTypes } from '../components/alerts/errors/error-map';
import type { UiNodeWithNameAttribute } from '../models/ory-extensions';
import type { RecoveryPasswordApiResponse } from '../models/recover-password.api.response';
import { SendRecoveryCodeMutation } from '../mutation/send-recovery-code.mutation';
import { GetRecoveryFlowQuery } from '../queries/get-recovery-flow.query';

import { getStatusCodeFromApolloError } from './get-status-code-from-apollo-error';
import {
    convertMessageIdToErrorType,
    getErrorMessageIdFromRecoveryFlow,
} from './helpers/recover-flow-error.helper';
import { KratosApiClient } from './kratos-api-client';

export const sendRecoveryCodeApi = async (
    email: string,
    callBackUrl: string
): Promise<RecoveryPasswordApiResponse> => {
    let error: ErrorMessageTypes = '500_RecoverPasswordGenericError';

    try {
        let csrfToken = '';
        let flowId = '';
        // Generate a recovery flow
        const { data } = await KratosApiClient.query<{
            recoveryFlow: RecoveryFlow;
        }>({
            query: GetRecoveryFlowQuery,
            fetchPolicy: 'no-cache',
            errorPolicy: 'all',
            variables: {
                returnUrl: callBackUrl,
            },
        });
        if (data && data.recoveryFlow) {
            const { recoveryFlow } = data;
            const msgId = getErrorMessageIdFromRecoveryFlow(recoveryFlow);
            if (msgId) {
                return {
                    success: false,
                    error: convertMessageIdToErrorType(msgId, 'send-code'),
                };
            }
            flowId = recoveryFlow.id;
            const defaultNodes = recoveryFlow.ui.nodes.filter(
                (node) => node.group == 'default'
            );
            const defaultNodeReparsed: UiNodeWithNameAttribute[] = JSON.parse(
                JSON.stringify(defaultNodes)
            );
            if (defaultNodeReparsed) {
                const csrfTokenNode = defaultNodeReparsed.find(
                    (x) => x.attributes.name === 'csrf_token'
                );
                csrfToken = csrfTokenNode?.attributes.value;
            }

            try {
                // Send OTP using recovery flow
                const sendVerificationCodeRequest = {
                    email,
                    method: 'code',
                    csrf_token: csrfToken,
                };
                const { data: sendData } = await KratosApiClient.mutate<{
                    sendRecoveryCodeResponse: RecoveryFlow;
                }>({
                    mutation: SendRecoveryCodeMutation,
                    errorPolicy: 'all',
                    variables: {
                        requestBody: sendVerificationCodeRequest,
                        flowId,
                    },
                });

                if (sendData && sendData.sendRecoveryCodeResponse) {
                    const { sendRecoveryCodeResponse } = sendData;
                    const msgId = getErrorMessageIdFromRecoveryFlow(
                        sendRecoveryCodeResponse
                    );
                    if (msgId) {
                        // Error messages sent in 200 response code
                        return {
                            success: false,
                            error: convertMessageIdToErrorType(
                                msgId,
                                'send-code'
                            ),
                        };
                    }
                }
                return { flowId, csrfToken, success: true };
            } catch (err) {
                // Error in sending Recovery Code
                error = getErrorMessageTypeForSendRecoveryCodeApi(
                    err as ApolloError
                );
            }
        }
    } catch (err) {
        //Error in generating recovery flow. It only returns GenericError
        return { success: false, error };
    }
    return { success: false, error };
};

const getErrorMessageTypeForSendRecoveryCodeApi = (
    error?: ApolloError
): ErrorMessageTypes => {
    if (error) {
        const statusCode = getStatusCodeFromApolloError(error);
        if (!statusCode) {
            return '500_RecoverPasswordGenericError';
        }
        const serverError = error.networkError as ServerError;
        const recoveryFlowError = serverError.result as RecoveryFlow;
        const msgId = getErrorMessageIdFromRecoveryFlow(recoveryFlowError);
        switch (statusCode) {
            case 400:
                if (msgId) {
                    return convertMessageIdToErrorType(msgId, 'send-code');
                }
        }
    }
    return '500_RecoverPasswordGenericError';
};
