import { gql } from '@apollo/client';

import type { ChallengeQuestionsFormModel } from '../models/form.models';

export const CreateAccountMutation = gql`
    mutation CreateAccountMutation {
        createAccountResponse(input: $requestBody)
            @rest(type: accountResponse, method: POST, path: "/credentials") {
            kratosSessionId
        }
    }
`;

export type CreateAccountResponse = {
    kratosSessionId: string;
};

export type AccountModel = {
    firstName: string;
    lastName: string;
    dateOfBirth: string;
    phoneNumber: string;
    email: string;
    password: string;
    address: ChallengeQuestionsFormModel['address'] | null;
};

export type CreateAccountRequest = {
    patient: AccountModel;
    deviceToken?: string;
    patientProfile?: string;
};
