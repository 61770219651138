import { Alert } from '@phx/design-system';
import { useTranslation } from 'react-i18next';

export const PhoneExistsError = () => {
    const { t } = useTranslation();

    return (
        <Alert data-testid="phone-exists-error" severity="error">
            {t('formErrors.phoneExistsError.tryAnother')}
        </Alert>
    );
};
