import { Alert } from '@phx/design-system';
import { useTranslation } from 'react-i18next';

export const BadPhoneNumberError = () => {
    const { t } = useTranslation();
    return (
        <Alert data-testid="bad-phone-number-error" severity="error">
            {t('formErrors.badPhoneNumberError.provideAnotherNumber')}
        </Alert>
    );
};
