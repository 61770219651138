import { gql } from '@apollo/client';

export const GetRecoveryFlowQuery = gql`
    query GetRecoveryFlow {
        recoveryFlow(returnUrl: $returnUrl)
            @rest(
                type: "RecoveryFlow"
                path: "/self-service/recovery/browser?return_to={args.returnUrl}"
            ) {
            id
            expires_at
            issued_at
            ui {
                messages {
                    context
                    id
                    text
                    type
                }
                nodes {
                    group
                    attributes {
                        name
                        onclick
                        type
                        value
                    }
                    messages {
                        context
                        id
                        text
                        type
                    }
                    meta {
                        label
                    }
                    type
                }
            }
        }
    }
`;
