import { gql } from '@apollo/client';

export const ChangePasswordMutation = gql`
    mutation ChangePasswordMutation {
        updatePasswordResponse(input: $requestBody)
            @rest(
                type: updatePasswordResponse
                method: POST
                path: "/credentials/update-password"
            ) {
            success
        }
    }
`;

export type ChangePasswordErrorResponse = {
    InvalidCurrentPassword?: string[];
    InvalidNewPassword?: string[];
};
