import { Body1, Heading1, Stack, useNavBar } from '@phx/design-system';
import { useForm, useHotkeys } from '@phx/design-system/hooks';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { z } from 'zod';

import { ChangePasswordApi } from '../../api/change-password.api';
import {
    ErrorMap,
    type ErrorMessageTypes,
} from '../../components/alerts/errors/error-map';
import { ConfirmPasswordField } from '../../components/form-fields/ConfirmPasswordField';
import { CurrentPasswordField } from '../../components/form-fields/CurrentPasswordField';
import { PasswordField } from '../../components/form-fields/PasswordField';
import { SubmitButton } from '../../components/form-fields/SubmitButton';
import { PageLayout } from '../../components/layout/PageLayout';
import { CONSTANTS } from '../../constants';
import { type UpdatePasswordFormModel } from '../../models/form.models';

const ChangePassword = () => {
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    const { setBackOverrideState } = useNavBar();
    const [loading, setLoading] = useState<boolean>(false);
    const [errorMessageType, setErrorMessageType] = useState<
        ErrorMessageTypes | undefined
    >(undefined);

    const ErrorMessage = errorMessageType ? ErrorMap[errorMessageType] : null;
    const callBackUrl = searchParams.get('callback');

    const form = useForm<UpdatePasswordFormModel>({
        initialValues: {
            currentPassword: '',
            password: '',
            confirmPassword: '',
        },
        schema: z.object({
            password: z
                .string({ message: t('passwordHelpText.enterPasswordSixChar') })
                .trim()
                .min(CONSTANTS.minimumPasswordLength, {
                    message: t('passwordHelpText.enterPasswordSixChar'),
                })
                .regex(/^\S*$/, {
                    message: t('passwordHelpText.containSpaces'),
                }),
        }),
    });

    const onSaveClick = async (formData: UpdatePasswordFormModel) => {
        setErrorMessageType(undefined);
        setLoading(true);
        const { success: changePasswordSuccess, error: changePasswordError } =
            await ChangePasswordApi(
                formData.currentPassword,
                formData.password
            );
        if (changePasswordSuccess) {
            if (callBackUrl) {
                window.location.replace(new URL(callBackUrl));
            }
            return;
        }
        setErrorMessageType(changePasswordError);
        if (changePasswordError === '400_InvalidPasswordError') {
            form.setFieldError(
                'currentPassword',
                t('formErrors.formErrorMessage.invalidPassword')
            );
        }
        setLoading(false);
    };

    useHotkeys([['Enter', () => form.onSubmit(onSaveClick)]]);

    const onBackButtonClick = useMemo(
        () =>
            callBackUrl
                ? () => window.location.replace(new URL(callBackUrl))
                : undefined,
        [callBackUrl]
    );

    useEffect(() => {
        if (onBackButtonClick) {
            setBackOverrideState({
                overrideFn: onBackButtonClick,
            });
        }

        return () => setBackOverrideState({ overrideFn: null });
    }, [onBackButtonClick]);

    return (
        <PageLayout
            loadingOverlay={loading}
            header={errorMessageType && ErrorMessage && <ErrorMessage />}
            heading={
                <Stack gap="sm">
                    <Heading1>
                        {t('changePasswordPage.changePassword')}
                    </Heading1>
                    <Body1>{t('changePasswordPage.createNewPassword')}</Body1>
                </Stack>
            }
            footer={
                <SubmitButton form="change-password-form">
                    {t('changePasswordPage.savePassword')}
                </SubmitButton>
            }
        >
            <form
                id="change-password-form"
                onSubmit={form.onSubmit(onSaveClick)}
            >
                <Stack gap="md">
                    <CurrentPasswordField form={form} page="CHANGE_PASSWORD" />
                    <PasswordField
                        form={form}
                        page="CHANGE_PASSWORD"
                        label={t('confirmPasswordForm.newPassword')}
                    />
                    <ConfirmPasswordField form={form} page="CHANGE_PASSWORD" />
                </Stack>
            </form>
        </PageLayout>
    );
};

export default ChangePassword;
