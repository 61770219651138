import {
    useAppInsightsContext,
    useTrackEvent,
} from '@microsoft/applicationinsights-react-js';
import { Anchor } from '@phx/design-system';

type PolicyTextButtonProps = {
    linkText: string;
    linkType: string;
    eventName: string;
};

export const PolicyTextButton = ({
    linkText,
    linkType,
    eventName,
}: PolicyTextButtonProps) => {
    const appInsights = useAppInsightsContext();
    const trackEvent = useTrackEvent(
        appInsights,
        `SIGNUP_${eventName}_CLICK`,
        {}
    );
    return (
        <Anchor
            href={linkType}
            target="_blank"
            onClick={() => {
                trackEvent({
                    message: `${linkText} link is clicked`,
                });
            }}
            data-testid={linkText.split(' ').join('-')}
        >
            {linkText}
        </Anchor>
    );
};
