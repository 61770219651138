import type { useForm } from '@phx/design-system/hooks';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export type FormWithConfirmPassword = {
    password: string;
    confirmPassword: string;
};

export const useConfirmPasswordValidation = <
    T extends FormWithConfirmPassword = FormWithConfirmPassword,
>(
    form: ReturnType<typeof useForm<T>>
) => {
    const { t } = useTranslation();

    const checkPassword = useCallback(
        (password: string, confirmPassword: string) => {
            if (!form.isTouched('confirmPassword')) {
                return;
            }

            if (password && confirmPassword && confirmPassword !== password) {
                form.setFieldError(
                    'confirmPassword',
                    t('confirmPasswordHelpText.tryAgain')
                );

                return;
            }

            form.clearFieldError('confirmPassword');
        },
        [form]
    );

    useEffect(() => {
        const { confirmPassword, password } = form.values;

        checkPassword(password, confirmPassword);
    }, [form.values.confirmPassword, form.values.password]);

    return { checkPassword };
};
