import { type JWTPayload, decodeJwt } from 'jose';

import { CONSTANTS } from '../constants';

import {
    getLocalStorageItem,
    removeLocalStorageItem,
} from './local-storage/local-storage-operations';

export const getTokenFromLocalStorageIfValid = () => {
    const token = getLocalStorageItem(CONSTANTS.tokenKey);
    if (token) {
        try {
            const decodedToken: JWTPayload = decodeJwt(token);
            if (decodedToken.exp && 1000 * decodedToken.exp > Date.now()) {
                return token; // valid
            } else {
                // remove expired token
                removeLocalStorageItem(CONSTANTS.tokenKey);
            }
        } catch {
            /* empty */
        }
    }
    return undefined;
};
