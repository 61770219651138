import { default as i18n } from 'i18next';
import resourcesToBackend from 'i18next-resources-to-backend';
import { initReactI18next } from 'react-i18next';

i18n.use(
    resourcesToBackend(async (lang: string, ns: string) => {
        const result = await fetch(`/locales/${lang}/${ns}.json`);
        if (!result.ok) {
            return null;
        }

        return await result.json();
    })
)
    .use(initReactI18next)
    .init({
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false,
        },
        ns: ['sso', 'pds', 'phx-lib', 'tlk'],
        defaultNS: 'sso',
        returnEmptyString: false,
    });

export default i18n;
