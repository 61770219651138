import { gql } from '@apollo/client';

export const SendRecoveryCodeMutation = gql`
    mutation SendRecoveryCodeMutation {
        sendRecoveryCodeResponse(input: $requestBody, id: $flowId)
            @rest(
                type: sendRecoveryCodeResponse
                method: POST
                path: "/self-service/recovery?flow={args.id}"
            ) {
            id
            expires_at
            issued_at
            ui {
                messages {
                    context
                    id
                    text
                    type
                }
                nodes {
                    group
                    attributes {
                        name
                        onclick
                        type
                        value
                    }
                    messages {
                        context
                        id
                        text
                        type
                    }
                    meta {
                        label
                    }
                    type
                }
            }
        }
    }
`;
