import { GraphQLError, GraphQLScalarType, Kind } from 'graphql';
const NPI_REGEX = /^\d{10}$/;
export const npiScalar = new GraphQLScalarType({
    name: 'NPI',
    description: 'NPI custom scalar type',
    serialize: (value) => {
        if (!(typeof value === 'string')) {
            throw new GraphQLError(`GraphQL serializer expected a 'string' but got ${typeof value}.`);
        }
        return isNpi(value);
    },
    parseValue: (value) => {
        if (!(typeof value === 'string')) {
            throw new GraphQLError(`GraphQL parser expected a 'string' but got ${typeof value}.`);
        }
        return isNpi(value);
    },
    parseLiteral: (ast) => {
        if (ast.kind === Kind.STRING) {
            return ast.value.toString();
        }
        throw new GraphQLError(`GraphQL parser expected a 'string'.`);
    },
});
function isNpi(value) {
    if (!NPI_REGEX.test(value)) {
        throw new GraphQLError(`Provided value: ${value}, is not a valid npi.`, {
            extensions: { code: 'BAD_USER_INPUT' },
        });
    }
    return value;
}
