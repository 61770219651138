import {
    useAppInsightsContext,
    useTrackEvent,
} from '@microsoft/applicationinsights-react-js';
import { Body1, Heading1, Stack, useNavBar } from '@phx/design-system';
import { useForm, useHotkeys } from '@phx/design-system/hooks';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { z } from 'zod';

import { recoverPasswordApi } from '../../api/recover-password.api';
import { PageLayout } from '../../components';
import {
    ErrorMap,
    type ErrorMessageTypes,
} from '../../components/alerts/errors/error-map';
import { ConfirmPasswordField } from '../../components/form-fields/ConfirmPasswordField';
import { PasswordField } from '../../components/form-fields/PasswordField';
import { SubmitButton } from '../../components/form-fields/SubmitButton';
import { CONSTANTS } from '../../constants';
import type { ChangePasswordFormModel } from '../../models/form.models';

export type RecoverPasswordPageState = {
    email: string;
    settingsFlowId: string;
};

/* This component is used to create new password */
const RecoverPassword = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const appInsights = useAppInsightsContext();
    const state = location?.state as RecoverPasswordPageState;
    const { setBackOverrideState } = useNavBar();

    const form = useForm<ChangePasswordFormModel>({
        validateInputOnBlur: true,
        initialValues: {
            password: '',
            confirmPassword: '',
        },
        schema: z.object({
            password: z
                .string({ message: t('passwordHelpText.enterPasswordSixChar') })
                .trim()
                .min(CONSTANTS.minimumPasswordLength, {
                    message: t('passwordHelpText.enterPasswordSixChar'),
                })
                .regex(/^\S*$/, {
                    message: t('passwordHelpText.containSpaces'),
                }),
        }),
    });
    const [loading, setLoading] = useState<boolean>(false);
    const [errorMessageType, setErrorMessageType] = useState<
        ErrorMessageTypes | undefined
    >();

    const ErrorMessage = errorMessageType ? ErrorMap[errorMessageType] : null;
    const trackSubmit = useTrackEvent(
        appInsights,
        'RECOVER_PASSWORD_SUBMIT_CLICK',
        {}
    );

    const onCreateClick = async (formData: ChangePasswordFormModel) => {
        trackSubmit({
            message: 'Submit button in setting up new password is clicked',
        });
        setErrorMessageType(undefined);
        setLoading(true);

        const {
            success: changePasswordSuccess,
            session,
            returnUrl,
            error: changePasswordError,
        } = await recoverPasswordApi(
            formData.password,
            state?.settingsFlowId ?? ''
        );

        if (changePasswordSuccess) {
            // Redirect back to sso page for getting this session converted to token
            const newUrl = `${returnUrl}&session=${session}&authMethod=${CONSTANTS.defaultAuth}`;
            window.location.replace(new URL(newUrl));

            return;
        }
        setErrorMessageType(changePasswordError);
        setLoading(false);
    };

    useHotkeys([['Enter', () => form.onSubmit(onCreateClick)]]);

    const onRestartRecoverFlow = () => {
        navigate(-2);
    };

    const onBackButtonClick = useCallback(() => {
        navigate(-1);
    }, []);

    useEffect(() => {
        setBackOverrideState({
            overrideFn: onBackButtonClick,
        });

        return () => setBackOverrideState({ overrideFn: null });
    }, []);

    return (
        <PageLayout
            loadingOverlay={loading}
            header={
                errorMessageType &&
                ErrorMessage && (
                    <ErrorMessage onLinkClick={onRestartRecoverFlow} />
                )
            }
            heading={
                <Stack gap="sm">
                    <Heading1>
                        {t('recoverPasswordPage.changePassword')}
                    </Heading1>
                    <Body1>{t('recoverPasswordPage.createPassword')}</Body1>
                </Stack>
            }
            footer={
                <SubmitButton form="recover-password-form">
                    {t('recoverPasswordPage.savePassword')}
                </SubmitButton>
            }
        >
            <form
                id="recover-password-form"
                onSubmit={form.onSubmit(onCreateClick)}
            >
                <Stack gap="md">
                    <PasswordField
                        form={form}
                        page="RECOVER_PASSWORD"
                        label={t('confirmPasswordForm.newPassword')}
                    />
                    <ConfirmPasswordField form={form} page="RECOVER_PASSWORD" />
                </Stack>
            </form>
        </PageLayout>
    );
};
export default RecoverPassword;
