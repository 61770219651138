import { Body1 } from '@phx/design-system';
import { Trans, useTranslation } from 'react-i18next';

import { PolicyTextButton } from '../../components';
import { CONSTANTS } from '../../constants';

export const PolicyFooter = () => {
    const { t } = useTranslation();

    const termsConditionsText = t('policyFooter.termsAndConditions');
    const privacyPolicyText = t('policyFooter.privacyPolicy');
    const waPrivacyNoticeText = t('policyFooter.WAPrivacyNotice');

    return (
        <Body1>
            <Trans i18nKey="policyFooter.footer">
                {'By clicking continue you accept our '}
                <PolicyTextButton
                    linkText={termsConditionsText}
                    linkType={CONSTANTS.termsConditionsLink}
                    eventName="T&C"
                />
                {', '}
                <PolicyTextButton
                    linkText={privacyPolicyText}
                    linkType={CONSTANTS.privacyPolicyLink}
                    eventName="PP"
                />
                {', and '}
                <PolicyTextButton
                    linkText={waPrivacyNoticeText}
                    linkType={CONSTANTS.waPrivacyNoticeLink}
                    eventName="WPN"
                />
            </Trans>
        </Body1>
    );
};
