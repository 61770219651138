import { decodeJwt } from 'jose';
import { getTokensFromRefreshToken } from '../get-auth-tokens-from-refresh-token';
import { Flow, initiateLoginChallenge, } from '../initiate-auth-flow';
import { CONSTANTS } from './constants';
const TOKEN_EXPIRATION_OFFSET = 300000;
export const getAccessToken = () => {
    const tokens = localStorage.getItem(CONSTANTS.tokenKey);
    const parsedTokens = JSON.parse(tokens || '{}');
    if (parsedTokens)
        return parsedTokens.accessToken;
    return undefined;
};
export const getRefreshToken = () => {
    const tokens = localStorage.getItem(CONSTANTS.tokenKey);
    const parsedTokens = JSON.parse(tokens || '{}');
    if (parsedTokens)
        return parsedTokens.refreshToken;
    return undefined;
};
export const removeLocalStorageItem = (key) => {
    return localStorage.removeItem(key);
};
export const getTokenFromStorage = (key) => {
    return localStorage.getItem(key);
};
export const setLocalStorageItem = (key, value) => {
    localStorage.setItem(key, value);
};
/** If there is no exp on token assume token is expired! */
export const isTokenExpired = (token) => token?.exp
    ? Date.now() >= token.exp * 1000 - TOKEN_EXPIRATION_OFFSET
    : true;
/** Checks if a non-expired access token exists in cache and returns it.
 * If no access token is in cache or the token has expired attempt to use refresh token if present
 * to get a new access token. If refresh token is missing or expired send user to login flow.*/
export const acquireToken = async (tokenUrl, clientId, ssoUrl, redirectUri, preventRedirect) => {
    try {
        let errorMessage = 'Login required';
        const tokens = localStorage.getItem(CONSTANTS.tokenKey);
        if (tokens) {
            const parsedTokens = JSON.parse(tokens);
            /** Return access token if present and not expired */
            if (parsedTokens?.accessToken) {
                const decodedAccessToken = decodeJwt(parsedTokens.accessToken);
                if (decodedAccessToken.exp &&
                    !isTokenExpired(decodedAccessToken)) {
                    return parsedTokens.accessToken;
                }
            }
            /** Attempt to use refresh token to get a new access token*/
            if (parsedTokens?.refreshToken) {
                const refreshResult = await getTokensFromRefreshToken({
                    responseCode: parsedTokens.refreshToken,
                    tokenUrl,
                    ssoClientId: clientId,
                });
                // Refresh was a success
                if (!refreshResult?.error) {
                    const accessToken = getAccessToken();
                    if (accessToken &&
                        !isTokenExpired(decodeJwt(accessToken))) {
                        return accessToken;
                    }
                }
                else {
                    errorMessage = refreshResult?.error;
                }
            }
        }
        throw new Error(errorMessage);
    }
    catch (err) {
        /** When all else fails initiate new login
         * NOTE: we probably want to capture telemetry here
         */
        const props = {
            currentUrl: window.location.pathname,
            flow: Flow.SignIn,
            mustReauthenticate: false,
            ssoUrl,
            ssoClientId: clientId,
            redirectUri,
        };
        if (!preventRedirect) {
            await initiateLoginChallenge(props);
        }
        else {
            return undefined;
        }
    }
};
