import {
    useAppInsightsContext,
    useTrackEvent,
} from '@microsoft/applicationinsights-react-js';
import { TextInput, type TextInputProps } from '@phx/design-system';
import { useForm } from '@phx/design-system/hooks';
import type { FocusEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

import { CONSTANTS } from '../../constants';

export type FormWithPhoneNumber = { phoneNumber: string };
export type PhoneNumberFieldProps<T extends FormWithPhoneNumber> = {
    form: ReturnType<typeof useForm<T>>;
    label?: string;
    page: string;
};

const phoneNumberSchema = z
    .string()
    .refine((val) => /^\d{10}$/.test(val))
    .transform(
        (val) => `(${val.slice(0, 3)}) ${val.slice(3, 6)}-${val.slice(6)}`
    );

export const PhoneNumberField = <
    T extends FormWithPhoneNumber = FormWithPhoneNumber,
>({
    form,
    label,
    page,
}: PhoneNumberFieldProps<T>) => {
    const { t } = useTranslation();
    const appInsights = useAppInsightsContext();

    const track = useTrackEvent(appInsights, `${page}_PHONE_CLICK`, {});

    const props = form.getInputProps('phoneNumber');
    props.onFocus = () => {
        track({ message: 'Phone number field is clicked' });
        form.getInputProps('phoneNumber').onFocus();
    };
    props.onBlur = (e: FocusEvent<HTMLInputElement>) => {
        // Autofill can change the value of the field without triggering onChange
        if (e.target.value !== form.values.phoneNumber) {
            // @ts-expect-error -- I don't know why TypeScript can't figure this out
            form.setFieldValue('phoneNumber', e.target.value);
        }
        form.getInputProps('phoneNumber').onBlur();
    };

    const onPaste: TextInputProps['onPaste'] = (e) => {
        const clipboardData = e.clipboardData.getData('Text');
        const parsedData = phoneNumberSchema.safeParse(clipboardData);
        form.setFieldValue(
            'phoneNumber',
            // @ts-expect-error -- I don't know why TypeScript can't figure this out
            parsedData.success ? parsedData.data : clipboardData
        );
    };

    return (
        <TextInput
            key={form.key('phoneNumber')}
            name="phoneNumber"
            label={label || t('createAccountForm.phoneNumber')}
            placeholder={CONSTANTS.phonePlaceHolder}
            mask={CONSTANTS.phonePlaceHolder}
            required
            onPaste={onPaste}
            {...props}
        />
    );
};
