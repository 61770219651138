import {
    useAppInsightsContext,
    useTrackEvent,
} from '@microsoft/applicationinsights-react-js';
import {
    Anchor,
    Body1,
    Box,
    Flex,
    Heading1,
    Stack,
    useNavBar,
} from '@phx/design-system';
import { useForm, useHotkeys } from '@phx/design-system/hooks';
import { TalkativeWidget } from '@phx/talkative';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { z } from 'zod';

import { sendRecoveryCodeApi } from '../../api/send-recovery-code.api';
import { verifyRecoveryCodeApi } from '../../api/verify-recovery-code.api';
import { PageLayout } from '../../components';
import {
    ErrorMap,
    type ErrorMessageTypes,
} from '../../components/alerts/errors/error-map';
import { InfoMap } from '../../components/alerts/information/information-map';
import { SubmitButton } from '../../components/form-fields/SubmitButton';
import { VerificationCodeField } from '../../components/form-fields/VerificationCodeField';
import type { VerifyFormModel } from '../../models/form.models';
import { routes } from '../../routes';

import type { RecoverPasswordPageState } from './RecoverPasswordPage';

export type RecoverPasswordVerifyCodePageState = {
    email: string;
    token: string;
    flowId: string;
};

const RecoverPasswordVerifyCode = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const appInsights = useAppInsightsContext();
    const { setBackOverrideState } = useNavBar();

    const state = location?.state as RecoverPasswordVerifyCodePageState;
    const [searchParams] = useSearchParams();
    const [token, setToken] = useState<string>(state?.token ?? '');
    const [flowId, setFlowId] = useState<string>(state?.flowId ?? '');

    const [showResendMessage, setShowResendMessage] = useState<boolean>(true);

    const form = useForm({
        validateInputOnBlur: true,
        initialValues: {
            code: '',
        },
        schema: z.object({
            code: z.string().regex(/^\d{6}$/, {
                message: t('formErrors.verificationCodeInvalid'),
            }),
        }),
    });
    const [loading, setLoading] = useState<boolean>(false);

    const [errorMessageType, setErrorMessageType] = useState<
        ErrorMessageTypes | undefined
    >();

    const Error = errorMessageType ? ErrorMap[errorMessageType] : null;
    const ResendMessage = showResendMessage
        ? InfoMap['RecoveryCodeResentInfo']
        : null;

    const trackSubmit = useTrackEvent(
        appInsights,
        'RECOVER_PASSWORD_VERIFY_CODE_SUBMIT_CLICK',
        {}
    );
    const trackResend = useTrackEvent(
        appInsights,
        'RECOVER_PASSWORD_VERIFY_CODE_RESEND_CLICK',
        {}
    );
    const trackBackButton = useTrackEvent(
        appInsights,
        'RECOVER_PASSWORD_VERIFY_CODE_BACK_BUTTON_CLICK',
        {}
    );

    const onNavigateBack = useCallback(() => {
        trackBackButton({ message: 'Back button in verify code is clicked' });
        navigate(-1);
    }, []);

    useEffect(() => {
        setBackOverrideState({ overrideFn: onNavigateBack });
        return () => setBackOverrideState({ overrideFn: null });
    }, []);

    const onSendRecoveryCode = async () => {
        trackResend({ message: 'Resend link is clicked' });
        setShowResendMessage(false);
        setErrorMessageType(undefined);
        setLoading(true);
        const callBackUrl = searchParams.get('callback');
        const {
            success: sendOtpSuccess,
            flowId,
            csrfToken,
            error: sendOtpError,
        } = await sendRecoveryCodeApi(state?.email, callBackUrl ?? '');
        setLoading(false);
        if (sendOtpSuccess) {
            setFlowId(flowId ?? '');
            setToken(csrfToken ?? '');
            setShowResendMessage(true);
            return;
        }
        setErrorMessageType(sendOtpError);
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onVerifyClick = async (data: any) => {
        trackSubmit({
            message: 'Verify button is clicked to verify the one-time password',
        });
        const formData = data as VerifyFormModel;
        setErrorMessageType(undefined);
        setShowResendMessage(false);
        setLoading(true);
        const {
            success: verifyRecoveryOtpSuccess,
            settingsFlowId,
            error: verifyRecoveryOtpError,
        } = await verifyRecoveryCodeApi(formData?.code, flowId, token);
        if (verifyRecoveryOtpSuccess) {
            const recoverPasswordPageState: RecoverPasswordPageState = {
                settingsFlowId: settingsFlowId ?? '',
                email: state?.email ?? '',
            };
            navigate(
                {
                    pathname: routes.recover,
                    search: window.location.search,
                },
                {
                    state: recoverPasswordPageState,
                }
            );
        }
        setLoading(false);
        setErrorMessageType(verifyRecoveryOtpError);
    };

    useHotkeys([['Enter', () => form.onSubmit(onVerifyClick)]]);

    const onRestartRecoverFlow = () => {
        trackSubmit({
            message: 'Restart recovery flow process link is clicked',
        });
        navigate(-1);
    };

    const genericText = t('recoverPasswordVerifyCode.genericEmailText');

    return (
        <PageLayout
            loadingOverlay={loading}
            header={
                errorMessageType && Error ? (
                    <Error onLinkClick={onRestartRecoverFlow} />
                ) : (
                    showResendMessage && ResendMessage && <ResendMessage />
                )
            }
            heading={
                <Stack gap="sm">
                    <Heading1>
                        {t('recoverPasswordVerifyCode.oneTimeVerificationCode')}
                    </Heading1>
                    <Body1>
                        {t(
                            'recoverPasswordVerifyCode.sixDigitVerificationCode',
                            {
                                email: state?.email ? state.email : genericText,
                            }
                        )}
                    </Body1>
                </Stack>
            }
            footer={
                <Stack gap="sm">
                    <Flex justify="center" gap="sm">
                        <TalkativeWidget />
                    </Flex>
                    <SubmitButton form="recover-password-verify-code-form">
                        {t('common.continue')}
                    </SubmitButton>
                </Stack>
            }
        >
            <Stack gap="lg">
                <form
                    id="recover-password-verify-code-form"
                    onSubmit={form.onSubmit(onVerifyClick)}
                >
                    {/* Do not remove this dummy text. Enter key press does not work as expected when there is only one input in form */}
                    <input type="text" hidden={true} name="dummy" />
                    <VerificationCodeField
                        form={form}
                        page="RECOVER_PASSWORD_VERIFY_CODE"
                    />
                </form>
            </Stack>
            <Stack gap="xs">
                <Body1>{t('recoverPasswordVerifyCode.receiveCode')}</Body1>
                <Box>
                    <Anchor
                        data-testid="icon-text-link"
                        onClick={onSendRecoveryCode}
                    >
                        {t('recoverPasswordVerifyCode.resendCode')}
                    </Anchor>
                </Box>
            </Stack>
        </PageLayout>
    );
};
export default RecoverPasswordVerifyCode;
