import { Alert, Anchor } from '@phx/design-system';
import { openTalkativeUI } from '@phx/talkative';
import { Trans } from 'react-i18next';

export const MultiplePatientsExistError = () => {
    return (
        <Alert data-testid="multiple-patients-exist-error" severity="error">
            <Trans i18nKey="formErrors.multiplePatientsExistError">
                Additional assistance is required to create your account. Please{' '}
                <Anchor onClick={openTalkativeUI}>contact us</Anchor>.
            </Trans>
        </Alert>
    );
};
