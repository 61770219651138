import { gql } from '@apollo/client';

export const LoginMutation = gql`
    mutation LoginMutation {
        loginResponse(input: $requestBody)
            @rest(
                type: loginResponse
                method: POST
                path: "/credentials/login"
            ) {
            kratosSessionId
        }
    }
`;

export type LoginResponse = {
    kratosSessionId: string;
};
