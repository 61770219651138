import {
    useAppInsightsContext,
    useTrackEvent,
} from '@microsoft/applicationinsights-react-js';
import { TextInput } from '@phx/design-system';
import { useForm } from '@phx/design-system/hooks';
import { useTranslation } from 'react-i18next';

export type FormWithEmail = { email: string };
export type EmailFieldProps<T extends FormWithEmail> = {
    form: ReturnType<typeof useForm<T>>;
    label?: string;
    page: string;
};

export const EmailField = <T extends FormWithEmail = FormWithEmail>({
    form,
    label,
    page,
}: EmailFieldProps<T>) => {
    const { t } = useTranslation();
    const appInsights = useAppInsightsContext();

    const track = useTrackEvent(appInsights, `${page}_EMAIL_CLICK`, {});

    const props = form.getInputProps('email');
    props.onFocus = () => {
        track({ message: 'Email is clicked' });
        form.getInputProps('email').onFocus();
    };

    return (
        <TextInput
            key={form.key('email')}
            type="email"
            name="email"
            label={label ? label : t('createAccountForm.emailAddress')}
            {...props}
        />
    );
};
