import { ApolloError, type ServerError } from '@apollo/client';
import type {
    ErrorBrowserLocationChangeRequired,
    RecoveryFlow,
} from '@ory/client';

import { type ErrorMessageTypes } from '../components/alerts/errors/error-map';
import type { RecoveryPasswordApiResponse } from '../models/recover-password.api.response';
import { SendRecoveryCodeMutation } from '../mutation/send-recovery-code.mutation';

import {
    convertMessageIdToErrorType,
    getErrorMessageIdFromRecoveryFlow,
} from './helpers/recover-flow-error.helper';
import KratosApiClient from './kratos-api-client';

export const verifyRecoveryCodeApi = async (
    code: string,
    flowId: string,
    csrfToken: string
): Promise<RecoveryPasswordApiResponse> => {
    const error: ErrorMessageTypes = '500_RecoverPasswordGenericError';

    try {
        const sendVerificationCodeRequest = {
            code,
            csrf_token: csrfToken,
        };
        const { data } = await KratosApiClient.mutate<{
            sendRecoveryCodeResponse: RecoveryFlow;
        }>({
            mutation: SendRecoveryCodeMutation,
            errorPolicy: 'all',
            variables: {
                requestBody: sendVerificationCodeRequest,
                flowId,
            },
        });
        if (data && data.sendRecoveryCodeResponse) {
            const { sendRecoveryCodeResponse } = data;
            const msgId = getErrorMessageIdFromRecoveryFlow(
                sendRecoveryCodeResponse
            );
            if (msgId) {
                return {
                    success: false,
                    error: convertMessageIdToErrorType(msgId, 'verify-code'),
                };
            }
            // If its success, it will be a 422, so the code below should not really execute
            return { success: true };
        }
    } catch (err) {
        return getCompleteRecoveryFlowErrorFromApolloError(err as ApolloError);
    }
    return { success: false, error };
};

export const getCompleteRecoveryFlowErrorFromApolloError = (
    recoveryError: ApolloError
): RecoveryPasswordApiResponse => {
    if (recoveryError.networkError) {
        const serverError = recoveryError.networkError as ServerError;
        if (serverError.statusCode === 422) {
            const errorBrowserLocationChange =
                serverError.result as ErrorBrowserLocationChangeRequired;
            const redirectUrl = errorBrowserLocationChange.redirect_browser_to;
            if (redirectUrl) {
                const index = redirectUrl?.search('flow=');
                if (redirectUrl.length > index + 5) {
                    return {
                        settingsFlowId: redirectUrl.substring(index + 5),
                        success: true,
                    };
                }
                return { success: false };
            } else {
                return {
                    success: false,
                    error: '500_RecoverPasswordGenericError',
                };
            }
        } else if (serverError.statusCode === 400) {
            const recoveryFlowError = serverError.result as RecoveryFlow;
            const msgId = getErrorMessageIdFromRecoveryFlow(recoveryFlowError);
            if (msgId) {
                return {
                    success: false,
                    error: convertMessageIdToErrorType(msgId, 'verify-code'),
                };
            }
        }
    }
    return { success: false, error: '500_RecoverPasswordGenericError' };
};
