import type { GenericApiResponse } from '../models/generic.api.response';
import { ClearCookieMutation } from '../mutation/clear-cookie.mutation';

import { SsoApiClient } from './sso-api-client';

export const clearCookie = async (
    clearSession: boolean = false,
    clearToken: boolean = false
): Promise<GenericApiResponse> => {
    try {
        await SsoApiClient.mutate({
            mutation: ClearCookieMutation,
            errorPolicy: 'all',
            variables: {
                clearSession,
                clearToken,
            },
        });
        return { success: true };
    } catch (err) {
        return { success: false };
    }
};
