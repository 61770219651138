import { useQuery } from '@apollo/client';
import { Box, Overlay } from '@phx/design-system';
import { NavigationBar } from '@phx/myphx-lib';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { ErrorMap } from '../../components/alerts/errors/error-map';
import { CONSTANTS } from '../../constants';
import {
    GetSsoRedirectPathQuery,
    type SsoRedirectResponse,
} from '../../queries/get-redirect-sso-path.query';

const SsoRedirect = () => {
    const [searchParams] = useSearchParams();
    const [acceptLoginInitiated, setAcceptLoginInitiated] =
        useState<boolean>(false);
    const loginChallenge = searchParams.get('login_challenge');
    const session = searchParams.get('session');
    const auth = searchParams.get('auth') ?? CONSTANTS.defaultAuth;

    const Error = ErrorMap['500_GenericError'];

    if (session && loginChallenge) {
        // Redirect to accept-login api only if both login_challenge and session exists
        if (!acceptLoginInitiated) {
            setAcceptLoginInitiated(true);
            window.location.replace(
                new URL(
                    `${
                        import.meta.env.VITE_SSO_LOGIN_API_URL
                    }/sso/accept-login?login_challenge=${loginChallenge}&session_id=${session}&auth_method=${auth}`
                )
            );
        }
    }

    // call initiate login api only if login_challenge is passed and session does not exist
    const {
        data: redirectPathResponse,
        loading,
        error: ssoError,
    } = useQuery<{ ssoRedirectResponse: SsoRedirectResponse }>(
        GetSsoRedirectPathQuery,
        {
            errorPolicy: 'all',
            skip: !!session || !loginChallenge, // skip if session exists or login challenge does not exist
            variables: {
                loginChallenge,
            },
        }
    );

    if (redirectPathResponse && redirectPathResponse.ssoRedirectResponse) {
        const { redirectUri, patientId, flow, mustReauthenticate } =
            redirectPathResponse.ssoRedirectResponse;
        const callBackUrl = window.location.href;
        const fixedParams = `${
            patientId?.length ? `&user=${patientId}` : ''
        }&callback=${callBackUrl}`;
        let url;
        switch (flow) {
            case 'employer':
                url = `${redirectUri}?showmember=true${fixedParams}`;
                break;
            case 'signIn':
                if (mustReauthenticate) {
                    url = `${redirectUri}?${fixedParams}`; // this will have extra &, test
                } else {
                    url = `${redirectUri}?showback=true${fixedParams}`;
                }
                break;
            case 'createAccount':
                url = `${redirectUri}?&showback=true${fixedParams}`;
                break;
            case 'updateProfile':
                url = `${redirectUri}?login_challenge=${loginChallenge}&callback=${callBackUrl}`;
                break;
            default:
                url = `${redirectUri}?${fixedParams}`; // this will have extra &, test
        }
        window.location.replace(new URL(url));
    }

    return (
        <Box>
            <NavigationBar />
            <Overlay visible={loading} loader fullScreen />
            {(!loginChallenge || ssoError) && Error && <Error />}
        </Box>
    );
};

export default SsoRedirect;
