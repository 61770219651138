import { CONSTANTS } from '../../constants';

export const getLocalStorageItem = (key: string): string | null =>
    window.localStorage.getItem(key);

export const setLocalStorageItem = (
    key: string,
    value: string,
    keyPrefix: string = '',
    clean: boolean = false
) => {
    window.localStorage.setItem(keyPrefix + key, value);
    if (clean) {
        deleteAllExpiredStateItems();
    }
};

export const removeLocalStorageItem = (key: string): void =>
    window.localStorage.removeItem(key);

export const deleteAllExpiredStateItems = () => {
    const currentTimeStamp = Date.now();
    const keysToDelete: string[] = [];
    // Find expired state items
    for (let i = 0; i < window.localStorage.length; i++) {
        const key = window.localStorage.key(i);
        if (key && key.startsWith(CONSTANTS.statePrefixLocalStorage)) {
            const keyWithoutPrefix = key.substring(
                CONSTANTS.statePrefixLocalStorage.length
            );
            const keyTimeStamp = parseInt(keyWithoutPrefix);
            if (keyTimeStamp < currentTimeStamp - CONSTANTS.stateExpiryTime) {
                keysToDelete.push(key);
            }
        }
    }
    //Delete expired keys
    for (const key in keysToDelete) {
        window.localStorage.removeItem(keysToDelete[key]);
    }
};
