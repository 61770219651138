import { gql } from '@apollo/client';

export const ClearCookieMutation = gql`
    mutation ClearCookieMutation {
        clearCookie(
            clearSession: $clearSession
            clearToken: $clearToken
            input: {}
        )
            @rest(
                type: clearCookie
                method: POST
                path: "/sso/clear-cookies?session={args.clearSession}&token={args.clearToken}"
            ) {
            success
        }
    }
`;
