export class PhoneNumberFormatter {
    static clean(phoneNumber = '') {
        return phoneNumber.replaceAll(/\D+/g, '');
    }
    static formatPhoneNumber(phoneNumber) {
        const cleanedPhoneNumber = PhoneNumberFormatter.clean(phoneNumber).trim();
        const countryCode = cleanedPhoneNumber.length > 10 ? '+' : '+1';
        return cleanedPhoneNumber ? `${countryCode}${cleanedPhoneNumber}` : '';
    }
    static maybeFormat(phoneNumber) {
        if (!phoneNumber) {
            return undefined;
        }
        const formatted = this.formatPhoneNumber(phoneNumber);
        return formatted === '' ? undefined : formatted;
    }
    static formatForApi(phoneNumber) {
        const cleanedPhoneNumber = PhoneNumberFormatter.clean(phoneNumber).trim();
        return cleanedPhoneNumber.length > 0
            ? // eslint-disable-next-line unicorn/no-nested-ternary
                cleanedPhoneNumber.startsWith('+')
                    ? cleanedPhoneNumber
                    : '+1' + cleanedPhoneNumber
            : '';
    }
    static formatForUI(phoneNumberString = '') {
        const phoneNumberRegex = /^(1|)?(\d{3})(\d{3})(\d{4})$/;
        const cleanedString = ('' + phoneNumberString).replaceAll(/\D/g, '');
        const match = phoneNumberRegex.exec(cleanedString);
        if (match) {
            return ['(', match[2], ') ', match[3], '-', match[4]].join('');
        }
        return '';
    }
}
