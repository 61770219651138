import { ApolloError } from '@apollo/client';

import type { ErrorMessageTypes } from '../components/alerts/errors/error-map';
import { CONSTANTS } from '../constants';
import type { ChallengeQuestionsFormModel } from '../models/form.models';
import type { GenericApiResponse } from '../models/generic.api.response';
import type { SessionInformationApiResponse } from '../models/session-information.api.response';
import {
    CreateAccountMutation,
    type CreateAccountRequest,
} from '../mutation/create-account.mutation';
import { getTokenFromLocalStorageIfValid } from '../utils/get-token-from-local-storage';
import { setLocalStorageItem } from '../utils/local-storage/local-storage-operations';

import { getAccountErrorFromApolloError } from './get-account-error';
import { SsoApiClient } from './sso-api-client';

export type CreateAccountApiResponse = GenericApiResponse & {
    userId?: string;
    sessionId?: string;
};
export const createAccountApi = async (
    firstName: string,
    lastName: string,
    email: string,
    dateOfBirth: string,
    phoneNumber: string,
    password: string,
    masterId: string,
    address: ChallengeQuestionsFormModel['address'] | null = null
): Promise<SessionInformationApiResponse> => {
    let error: ErrorMessageTypes = '500_GenericError';

    const token = getTokenFromLocalStorageIfValid();

    const createAccountRequest: CreateAccountRequest = {
        patient: {
            firstName,
            lastName,
            dateOfBirth,
            phoneNumber,
            email,
            password,
            address,
        },
        deviceToken: token,
    };

    if (masterId !== '' && masterId !== undefined) {
        createAccountRequest.patientProfile = `patient/${masterId}`;
    }

    try {
        const { data } = await SsoApiClient.mutate({
            mutation: CreateAccountMutation,
            errorPolicy: 'all',
            variables: {
                requestBody: createAccountRequest,
            },
        });
        if (data && data.createAccountResponse) {
            const { kratosSessionId } = data.createAccountResponse;
            setLocalStorageItem(CONSTANTS.sessionKey, kratosSessionId);
            return { success: true, sessionId: kratosSessionId };
        }
    } catch (err) {
        const createAccountError = err as ApolloError;
        error = createAccountError
            ? getAccountErrorFromApolloError(createAccountError)
            : '500_GenericError';
    }
    return { success: false, error };
};
