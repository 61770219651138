export const generateCodeVerifier = () => {
    const len = 64; // Length of generated code verifier
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let codeVerifier = '';
    for (let i = 0; i < len; i++) {
        codeVerifier += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return codeVerifier;
};
export const generateChallenge = async (codeVerifier) => {
    const encoder = new TextEncoder();
    const data = encoder.encode(codeVerifier);
    const digest = await crypto.subtle.digest('SHA-256', data);
    const uint8Array = new Uint8Array(digest);
    const base64String = window.btoa(String.fromCharCode.apply(null, uint8Array));
    const base64URLEncoded = base64String
        .replace(/\+/g, '-')
        .replace(/\//g, '_')
        .replace(/=+$/, '');
    return base64URLEncoded;
};
export const generatePkceSet = async () => {
    const codeVerifier = generateCodeVerifier();
    const challenge = await generateChallenge(codeVerifier);
    return { challenge, codeVerifier };
};
export const matchStateFromLocalStorage = (state) => {
    const decodedState = window.atob(state);
    const stateObject = JSON.parse(decodedState);
    const localStorageState = localStorage.getItem(stateObject.state);
    if (localStorageState) {
        const decodedLocalStorageState = window.atob(localStorageState);
        const parsedObject = JSON.parse(decodedLocalStorageState);
        if (parsedObject.currentUrl === stateObject.currentUrl &&
            parsedObject.codeVerifier === stateObject.codeVerifier) {
            return {
                isValid: true,
                decodedState: parsedObject,
            };
        }
    }
    return { isValid: false };
};
export const getHydraTokens = async (code, ssoClientId, ssoTokenApi, forRefreshToken, codeVerifier, redirectUri) => {
    const formData = new URLSearchParams();
    formData.append('client_id', ssoClientId);
    if (forRefreshToken) {
        formData.append('grant_type', 'refresh_token');
        formData.append('refresh_token', code);
    }
    else {
        if (!redirectUri || !codeVerifier) {
            return Promise.reject('redirectUri or codeVerifier is missing');
        }
        formData.append('code', code);
        formData.append('grant_type', 'authorization_code');
        formData.append('redirect_uri', redirectUri);
        formData.append('code_verifier', codeVerifier);
    }
    return fetch(ssoTokenApi, {
        method: 'POST',
        headers: {
            'content-type': 'application/x-www-form-urlencoded',
        },
        body: formData.toString(),
    });
};
