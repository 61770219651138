import { ApolloError, type ServerError } from '@apollo/client';
import type { Session, SettingsFlow, UiNode } from '@ory/client';

import { type ErrorMessageTypes } from '../components/alerts/errors/error-map';
import { CONSTANTS } from '../constants';
import { type GenericApiResponse } from '../models/generic.api.response';
import { type UiNodeWithNameAttribute } from '../models/ory-extensions';
import { UpdatePasswordMutation } from '../mutation/update-password.mutation';
import { GetSessionInformationQuery } from '../queries/get-session-information.query';
import { GetSettingsFlowByIdQuery } from '../queries/get-settings-flow-by-id.query';
import { setLocalStorageItem } from '../utils/local-storage/local-storage-operations';

import { getStatusCodeFromApolloError } from './get-status-code-from-apollo-error';
import {
    convertMessageIdToErrorType,
    getErrorMessageIdFromSettingsFlow,
} from './helpers/recover-flow-error.helper';
import { KratosApiClient } from './kratos-api-client';

export type UpdatePasswordApiResponse = GenericApiResponse & {
    session?: string;
    user?: string;
    returnUrl?: string;
};

export const recoverPasswordApi = async (
    password: string,
    flowId: string
): Promise<UpdatePasswordApiResponse> => {
    let error: ErrorMessageTypes = '500_RecoverPasswordGenericError';

    try {
        let csrfToken = '';

        // first get the csrf token for settings flow
        const { data: settingsData } = await KratosApiClient.query<{
            settingsFlow: SettingsFlow;
        }>({
            query: GetSettingsFlowByIdQuery,
            fetchPolicy: 'no-cache',
            errorPolicy: 'all',
            variables: {
                flowId: flowId,
            },
        });
        const { settingsFlow } = settingsData;
        const defaultNodes = settingsFlow.ui.nodes.filter(
            (node: UiNode) => node.group == 'default'
        );
        const defaultNodeReparsed: UiNodeWithNameAttribute[] = JSON.parse(
            JSON.stringify(defaultNodes)
        );
        if (defaultNodeReparsed) {
            const csrfTokenNode = defaultNodeReparsed.find(
                (x) => x.attributes.name === 'csrf_token'
            );

            csrfToken = csrfTokenNode?.attributes.value;
        }

        // Now call change password API with this token
        const recoverPasswordRequest = {
            password,
            method: 'password',
            csrf_token: csrfToken,
        };
        const { data } = await KratosApiClient.mutate<{
            updatePasswordResponse: SettingsFlow;
        }>({
            mutation: UpdatePasswordMutation,
            errorPolicy: 'all',
            variables: {
                requestBody: recoverPasswordRequest,
                flowId,
            },
        });

        if (data && data.updatePasswordResponse) {
            const { updatePasswordResponse } = data;
            const msgId = getErrorMessageIdFromSettingsFlow(
                updatePasswordResponse
            );
            if (msgId) {
                return {
                    success: false,
                    error: convertMessageIdToErrorType(
                        msgId,
                        'change-password'
                    ),
                };
            }
            const returnUrl = updatePasswordResponse.return_to;
            const { data: sessionData } = await KratosApiClient.query<{
                session: Session;
            }>({
                query: GetSessionInformationQuery,
                fetchPolicy: 'no-cache',
                errorPolicy: 'all',
            });

            setLocalStorageItem(CONSTANTS.sessionKey, sessionData.session.id);
            return {
                session: sessionData.session.id,
                success: true,
                returnUrl,
            };
        }
        return { success: false };
    } catch (err) {
        const sendVerificationCodeError = err as ApolloError;
        error = getErrorMessageTypeForRecoverPasswordApi(
            sendVerificationCodeError
        );
    }
    return { success: false, error };
};

const getErrorMessageTypeForRecoverPasswordApi = (
    error?: ApolloError
): ErrorMessageTypes => {
    const serverError = error?.networkError as ServerError;
    if (error) {
        const statusCode = getStatusCodeFromApolloError(error);
        switch (statusCode) {
            case 400:
                if (serverError.statusCode === 400) {
                    const settingsFlowError =
                        serverError.result as SettingsFlow;
                    const msgId =
                        getErrorMessageIdFromSettingsFlow(settingsFlowError);
                    if (msgId) {
                        return convertMessageIdToErrorType(
                            msgId,
                            'change-password'
                        );
                    }
                }
        }
    }
    return '500_RecoverPasswordGenericError';
};
