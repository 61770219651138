import { NavBarProvider } from '@phx/design-system';
import { Outlet } from 'react-router-dom';

export const Layout = () => {
    return (
        <NavBarProvider>
            <Outlet />
        </NavBarProvider>
    );
};

export default Layout;
