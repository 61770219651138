import { ApolloError, type ServerError } from '@apollo/client';
import type {
    ErrorBrowserLocationChangeRequired,
    RecoveryFlow,
} from '@ory/client';

import type { ErrorMessageTypes } from '../components/alerts/errors/error-map';
import type { RecoveryPasswordApiResponse } from '../models/recover-password.api.response';
import { SendRecoveryCodeMutation } from '../mutation/send-recovery-code.mutation';

import { clearCookie } from './clear-cookie.api';
import KratosApiClient from './kratos-api-client';

export const verifyRecoveryCodeLinkApi = async (
    code: string,
    flowId: string
): Promise<RecoveryPasswordApiResponse> => {
    const error: ErrorMessageTypes = '500_RecoverPasswordLinkError';
    try {
        // Attempt to clear cookies so it does not get any issues from previous attempts
        await clearCookie(true, true);
        const verificationCodeRequest = {
            code,
        };
        // Verify code in the lunj
        await KratosApiClient.mutate<{
            sendRecoveryCodeResponse: RecoveryFlow;
        }>({
            mutation: SendRecoveryCodeMutation,
            errorPolicy: 'all',
            variables: {
                requestBody: verificationCodeRequest,
                flowId,
            },
        });
    } catch (err) {
        return getDetailsFromApolloError(err as ApolloError);
    }
    return { success: false, error };
};

const getDetailsFromApolloError = (
    recoveryError: ApolloError
): RecoveryPasswordApiResponse => {
    if (recoveryError.networkError) {
        const serverError = recoveryError.networkError as ServerError;
        if (serverError.statusCode === 422) {
            const errorBrowserLocationChange =
                serverError.result as ErrorBrowserLocationChangeRequired;
            const redirectUrl = errorBrowserLocationChange.redirect_browser_to;
            if (redirectUrl) {
                const index = redirectUrl?.search('flow=');
                if (redirectUrl.length > index + 5) {
                    return {
                        settingsFlowId: redirectUrl.substring(index + 5),
                        success: true,
                    };
                }
            }
        }
    }
    return {
        success: false,
        error: '500_RecoverPasswordLinkError',
    };
};
