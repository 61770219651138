import { Alert, Anchor } from '@phx/design-system';
import { TalkativeWidget } from '@phx/talkative';
import { Trans } from 'react-i18next';

export interface MaxRecoveryVerificationAttemptsReachedErrorProps {
    onLinkClick: () => void;
}

export const MaxRecoveryVerificationAttemptsReachedError = (
    props: MaxRecoveryVerificationAttemptsReachedErrorProps
) => {
    const { onLinkClick } = props;

    return (
        <Alert
            data-testid="max-recovery-attempts-reached-error"
            severity="error"
        >
            <Trans i18nKey="formErrors.maxRecoveryVerificationAttemptsError">
                Incorrect code. Please{' '}
                <Anchor onClick={onLinkClick}>try again</Anchor> or{' '}
                <TalkativeWidget />.
            </Trans>
        </Alert>
    );
};
