import { Alert } from '@phx/design-system';
import { useTranslation } from 'react-i18next';

export const MaxAttemptsReachedError = () => {
    const { t } = useTranslation();
    return (
        <Alert data-testid="max-attempts-reached-error" severity="error">
            {t('formErrors.maxAttemptsReachedError.tryAgain')}
        </Alert>
    );
};
