import { gql } from '@apollo/client';

import type { RedirectFlow } from '../models/redirect-flow';

export const GetSsoRedirectPathQuery = gql`
    query GetSsoRedirectPath {
        ssoRedirectResponse(loginChallenge: $loginChallenge)
            @rest(
                type: "SsoRedirectResponse"
                path: "/sso/initiate-idp-login?login_challenge={args.loginChallenge}"
            ) {
            redirectUri
            patientId
            flow
            mustReauthenticate
            employer
        }
    }
`;

export type SsoRedirectResponse = {
    redirectUri: string;
    patientId: string;
    flow: RedirectFlow;
    mustReauthenticate?: boolean;
    employer?: string;
};
