import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client';
import { validateAndGetAccessToken } from '@phx/front-end-auth';
import { RestLink } from 'apollo-link-rest';

const restLink = new RestLink({
    uri: import.meta.env.VITE_SSO_LOGIN_API_URL,
    credentials: 'include',
});

const authLink = new ApolloLink((operation, forward) => {
    // Retrieve the authorization token from local storage.
    const validAccessToken = validateAndGetAccessToken();

    // Use the setContext method to set the HTTP headers.
    operation.setContext({
        headers: {
            authorization: validAccessToken
                ? `Bearer ${validAccessToken}`
                : null,
        },
    });

    // Call the next link in the middleware chain.
    return forward(operation);
});

export const SsoApiClient = new ApolloClient({
    cache: new InMemoryCache(),
    link: authLink.concat(restLink as unknown as ApolloLink),
});

export default SsoApiClient;
