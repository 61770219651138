import { getHydraTokens, matchStateFromLocalStorage, } from './helpers/auth.helper';
import { CONSTANTS } from './helpers/constants';
import { getTokenFromStorage, setLocalStorageItem, } from './helpers/token.service';
export const getAuthTokensFromCode = async ({ responseCode, base64StateInUrl = '', ssoClientId, redirectUri, ssoTokenApi, }) => {
    if (responseCode) {
        const matchStateResponse = matchStateFromLocalStorage(base64StateInUrl);
        if (matchStateResponse.isValid) {
            const codeVerifierKey = `${matchStateResponse.decodedState?.state}${CONSTANTS.pkceCodeVerifierPrefix}`;
            const codeVerifier = getTokenFromStorage(codeVerifierKey) ?? '';
            const response = await getHydraTokens(responseCode, ssoClientId, ssoTokenApi, false, codeVerifier, redirectUri);
            const responseJson = await response.json();
            if (responseJson.access_token && responseJson.refresh_token) {
                const tokens = {
                    accessToken: responseJson.access_token,
                    refreshToken: responseJson.refresh_token,
                    exp: responseJson.expires_in,
                };
                setLocalStorageItem(CONSTANTS.tokenKey, JSON.stringify(tokens));
                const nextUrl = matchStateResponse.decodedState?.currentUrl;
                return { nextUrl };
            }
            else if (responseJson.error) {
                return {
                    error: responseJson.error,
                    errorDescription: responseJson.error_description,
                };
            }
            return { error: 'access or refresh token is missing' };
        }
        return {
            error: 'State does not match while getting code. Possible CSRF attack',
        };
    }
    return { error: 'response code is missing to get the tokens' };
};
