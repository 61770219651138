import { Alert, Anchor } from '@phx/design-system';
import { openTalkativeUI } from '@phx/talkative';
import { Trans } from 'react-i18next';

export interface RecoverPasswordGenericMessageErrorProps {
    onLinkClick: () => void;
}

export const RecoverPasswordGenericMessageError = (
    props: RecoverPasswordGenericMessageErrorProps
) => {
    const { onLinkClick } = props;

    return (
        <Alert data-testid="recover-generic-message-error" severity="error">
            <Trans i18nKey="formErrors.recoverPasswordGenericError">
                Something went wrong. Please{' '}
                <Anchor onClick={onLinkClick}>try again</Anchor> or{' '}
                <Anchor onClick={openTalkativeUI}>contact us</Anchor>.
            </Trans>
        </Alert>
    );
};
