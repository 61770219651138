import {
    useAppInsightsContext,
    useTrackEvent,
} from '@microsoft/applicationinsights-react-js';
import { PasswordInput } from '@phx/design-system';
import { useForm } from '@phx/design-system/hooks';
import { useTranslation } from 'react-i18next';

import {
    type FormWithConfirmPassword,
    useConfirmPasswordValidation,
} from '../../hooks/use-confirm-password-validation';
import type { FormWithPassword } from '../../hooks/use-password-validation';

export type ConfirmPasswordFieldProps<T extends FormWithPassword> = {
    form: ReturnType<typeof useForm<T>>;
    label?: string;
    page: string;
};

export const ConfirmPasswordField = <
    T extends FormWithConfirmPassword = FormWithConfirmPassword,
>({
    form,
    label,
    page,
}: ConfirmPasswordFieldProps<T>) => {
    const { t } = useTranslation();
    const appInsights = useAppInsightsContext();

    const track = useTrackEvent(appInsights, `${page}_PASSWORD_CLICK`, {});

    const { checkPassword } = useConfirmPasswordValidation(form);

    const inputProps = form.getInputProps('confirmPassword');
    inputProps.onFocus = () => {
        track({ message: 'Confirm password field is clicked' });
        form.getInputProps('confirmPassword').onFocus();
    };
    delete inputProps.onBlur;

    const handleBlur = () => {
        if (form.values.password) {
            checkPassword(form.values.password, form.values.confirmPassword);
        } else {
            form.getInputProps('confirmPassword').onBlur();
        }
    };

    return (
        <PasswordInput
            key={form.key('confirmPassword')}
            name="confirmPassword"
            label={label ? label : t('confirmPasswordForm.confirmPassword')}
            onBlur={handleBlur}
            {...inputProps}
        />
    );
};
